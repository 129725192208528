import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import Typography from '../../Typography';
import SparkLineChart from '../../IndicesOverview/IndexTile/IndexValueSparkLineChart';
import { DeltaType } from '../../DeltaChip';
import { SparklineCell as SparklineCellProps } from '../types';
import { isNull } from '../../../utils/isNull';
import isUndefined from '../../../utils/isUndefined';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

function formatPercent(value: number | undefined | null): string {
  if (isNull(value) || isUndefined(value)) {
    return '-%';
  }

  if (value === 0) {
    return '0%';
  }

  return value > 0 ? `+${value}%` : `${value}%`;
}

export default function SparklineCell({ data, deltaPct, cellClassName, sx }: SparklineCellProps): JSX.Element {
  const classes = useStyles();

  return (
    <TableCell className={cellClassName} sx={sx}>
      <div className={classes.container}>
        {Object.keys(data).length > 0 && (
          <SparkLineChart
            width={50}
            height={30}
            data={Object.entries(data).map((d) => ({ x: new Date(d[0]).getTime(), y: d[1] }))}
            type={
              // eslint-disable-next-line no-nested-ternary
              isNull(deltaPct) || deltaPct === 0
                ? DeltaType.Neutral
                : deltaPct > 0
                ? DeltaType.Positive
                : DeltaType.Negative
            }
          />
        )}
        <div>
          <Typography variant="subtitle2">{formatPercent(deltaPct)}</Typography>
        </div>
      </div>
    </TableCell>
  );
}
