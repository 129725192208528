import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { useQuery } from '@apollo/client';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import IconButton from '../IconButton';
import { useAddWatchListItem } from '../../hooks/useAddWatchListItem';
import { useDeleteWatchListItem } from '../../hooks/useDeleteWatchListItem';
import { logError } from '../../utils/logger';
import { graphql } from '../../__generated__';

const useStyles = makeStyles((theme) => ({
  inWatchlist: {
    color: theme.palette.textSecondary,
    backgroundColor: theme.palette.textPrimary,

    '&:hover': {
      // TODO: this colour is a placeholder, we need to find out
      // what colour the dark blue buttons should get on hover, however
      // as of right now I just don't have time to chase that up. I am
      // setting this here to prevent the material ui default hover colour
      // which is a really light grey that looks bad on these dark blue buttons.
      backgroundColor: theme.palette.textPrimary,
    },
  },
}));

export const WATCHLIST = graphql(`
  query Watchlist {
    watchListItems {
      asset {
        id
      }
    }
  }
`);

interface WatchlistButtonProps {
  assetId: number;
  onAdd: (assetId: number) => void;
  onRemove: (assetId: number) => void;
  onError: (errors: readonly GraphQLError[]) => void;
}

export default function WatchlistButton({ onAdd, onRemove, onError, assetId }: WatchlistButtonProps): JSX.Element {
  const classes = useStyles();
  // Instead of querying for each asset individually, just get the full watchlist so
  // that we can benefit from apollo cache (e.g. only needs 1 network request regardless
  // of how many buttons we render.)
  const { data: watchlistResponse, loading: loadingWatchlist, error: failedToLoadWatchlist } = useQuery(WATCHLIST);
  const { addToWatchlist, loading: addingToWatchlist } = useAddWatchListItem();
  const { removeFromWatchlist, loading: removingFromWatchlist } = useDeleteWatchListItem();

  const isOnWatchlist = watchlistResponse?.watchListItems.some((item) => item.asset.id === assetId);
  const [optimisticIsOnWatchlist, setOptimisticIsOnWatchlist] = React.useState(isOnWatchlist);

  React.useEffect(() => {
    if (isOnWatchlist && !optimisticIsOnWatchlist) {
      setOptimisticIsOnWatchlist(true);
    }

    if (!isOnWatchlist && optimisticIsOnWatchlist) {
      setOptimisticIsOnWatchlist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnWatchlist]);

  async function handleClick() {
    if (optimisticIsOnWatchlist) {
      setOptimisticIsOnWatchlist(false);
      const { errors } = await removeFromWatchlist({ assetId });
      if (errors) {
        setOptimisticIsOnWatchlist(true);
        logError({
          error: new Error('Failed to remove from watchlist'),
          originalError: errors[0],
          filename: 'WatchlistButton',
          additionalInfo: {
            assetId,
            errors: JSON.stringify(errors),
          },
        });
        onError(errors);
      } else {
        onRemove(assetId);
      }
    } else {
      setOptimisticIsOnWatchlist(true);
      const { errors } = await addToWatchlist({ assetId });
      if (errors) {
        setOptimisticIsOnWatchlist(false);
        logError({
          error: new Error('Failed to add to watchlist'),
          originalError: errors[0],
          filename: 'WatchlistButton',
          additionalInfo: {
            assetId,
            errors: JSON.stringify(errors),
          },
        });
        onError(errors);
      } else {
        onAdd(assetId);
      }
    }
  }

  return (
    <IconButton
      aria-label="watchlist"
      onClick={handleClick}
      className={clsx({ [classes.inWatchlist]: optimisticIsOnWatchlist })}
      variant="filled"
      disabled={loadingWatchlist || addingToWatchlist || removingFromWatchlist || Boolean(failedToLoadWatchlist)}
      size="large"
    >
      <VisibilityOutlined />
    </IconButton>
  );
}
