import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFooterStyles } from './styles';

export default function Footer(): JSX.Element {
  const classes = useFooterStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.footer}>
      <div className={classes.footerLinks}>
        <a href="https://www.cultx.com/privacy" className={classes.link} target="_blank" rel="noreferrer">
          {t('auth:termsAndConditionsLink')}
        </a>

        <a href="https://www.cultx.com/privacy" className={classes.link} target="_blank" rel="noreferrer">
          {t('auth:privacyPolicy')}
        </a>
        <a href="https://www.cultx.com" className={classes.link} target="_blank" rel="noreferrer">
          {t('auth:marketingConsent')}
        </a>
      </div>
    </div>
  );
}
