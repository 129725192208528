import { useFlags } from 'launchdarkly-react-client-sdk';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RRLink } from 'react-router-dom';
import LogoDarkBeta from '../../assets/images/logo-dark-beta.png';
import { isLoggedInVar } from '../../graphql/cache';
import { useSignOut } from '../../hooks/useSignOut';
import Typography from '../Typography';
import ListItem from './ListItem';
import { ReactComponent as TradeArrows } from '../../assets/icons/trade-arrows.svg';
import { ReactComponent as WineBottles } from '../../assets/icons/wine-bottles.svg';
import { ReactComponent as DiscoverEye } from '../../assets/icons/discover-eye.svg';
import { ReactComponent as WatchlistEye } from '../../assets/icons/watchlist-eye.svg';
import { ReactComponent as Wallet } from '../../assets/icons/wallet.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { NavigationPathEnum } from '../../types';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
    background: theme.palette.card.background.dark,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },

  button: {
    borderRadius: 24,
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.text.primary}`,
    textAlign: 'center',
    justifyContent: 'center',
    width: 'auto',
  },

  icon: {
    stroke: theme.palette.text.primary,
    width: '32px',
  },

  alignCenter: {
    textAlign: 'center',
  },
  p16: {
    padding: 16,
  },
  alink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bottom: {
    marginTop: 'auto',
  },
  versionNumber: {
    textAlign: 'right',
    overflowWrap: 'break-word',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}));

type MenuOption = {
  nameTranslated: string;
  icon: JSX.Element;
  link: string;
};

interface HamburgerMenuProps {
  className?: string;
}

export default function HamburgerMenu({ className = '' }: HamburgerMenuProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const signOut = useSignOut();
  const { displayInternalVersionNumber } = useFlags();

  const toggleDrawer = (_open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(_open);
  };

  async function handleSignOut() {
    await signOut();
  }

  const list = () => (
    <Box
      style={{ width: 250, height: '100vh', display: 'flex', flexDirection: 'column' }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className={classes.header}>
        <img src={LogoDarkBeta} width={160} alt="logo" />
      </div>
      <List>
        {(
          [
            // Hidden as it is not needed for the MVP
            // { nameTranslated: t('common:overview'), link: '/overview' },
            { nameTranslated: t('common:discover'), link: '/discover', icon: <DiscoverEye className={classes.icon} /> },
            {
              nameTranslated: t('account:userOptionsMenu.myWatchlist'),
              link: NavigationPathEnum.WATCHLIST,
              icon: <WatchlistEye className={classes.icon} />,
            },
            {
              nameTranslated: t('common:portfolio'),
              link: '/portfolio',
              icon: <WineBottles className={classes.icon} />,
            },
            { nameTranslated: t('common:myTrade'), link: '/trade', icon: <TradeArrows className={classes.icon} /> },
          ] as MenuOption[]
        )
          .filter((a) => a !== undefined)
          .map((item) => (
            <ListItem key={item.link} link={item.link} nameTranslated={item.nameTranslated} icon={item.icon} />
          ))}
      </List>
      <Divider variant="middle" />
      <List>
        {(
          [
            {
              nameTranslated: t('account:userOptionsMenu.myWallet'),
              link: NavigationPathEnum.WALLET,
              icon: <Wallet className={classes.icon} />,
            },
            {
              nameTranslated: t('account:userOptionsMenu.myAccountSettings'),
              link: NavigationPathEnum.MY_ACCOUNT,
              icon: <User className={classes.icon} />,
            },
          ] as MenuOption[]
        ).map((item) => (
          <ListItem key={item.link} link={item.link} nameTranslated={item.nameTranslated} icon={item.icon} />
        ))}
      </List>
      <Divider variant="middle" />
      <List>
        {isLoggedInVar() ? (
          <MuiListItem key="logout-button" onClick={handleSignOut} className={classes.button} button>
            <Typography variant="subtitle1">{t('common:signOut')}</Typography>
          </MuiListItem>
        ) : (
          <MuiListItem key="login-button" component={RRLink} to="/login" className={classes.button} button>
            <Typography variant="subtitle1">{t('common:login')}</Typography>
          </MuiListItem>
        )}
      </List>
      <div className={clsx(classes.alignCenter, classes.p16, classes.bottom)}>
        <a className={classes.alink} href="https://knowledge.cultx.com">
          <Typography customVariant="body1Bold">FAQs</Typography>
        </a>
      </div>
      {displayInternalVersionNumber &&
        process.env.REACT_APP_VERSION_NUMBER_INTERNAL &&
        process.env.REACT_APP_VERSION_NUMBER_INTERNAL.length > 0 && (
          <Typography className={classes.versionNumber} customVariant="navigation">
            {process.env.REACT_APP_VERSION_NUMBER_INTERNAL}
          </Typography>
        )}
    </Box>
  );

  return (
    <div className={className}>
      <IconButton aria-label="menu" onClick={toggleDrawer(true)} size="large">
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}
