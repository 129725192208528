/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/naming-convention */
import { Fees } from '@cultwines/zellar-client-sdk';
import Button from '@mui/material/Button';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import { calculateFee } from '@cultwines/zellar-client-sdk/utils/calculateFee';
import TextField from '@mui/material/TextField';
import { AlertTitle, Skeleton } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import CurrencyTextField from '../CurrencyTextField';
import Select from '../Select';
import { selectExpirationDateOptions } from '../Select/constants';
import UnitSizeSelect from '../Select/UnitSizeSelect';
import Typography from '../Typography';
import { useStyles } from './styles';
import CostBreakdownComponent from '../CostBreakdown';
import { Mode } from '../OrderModal/types';
import { useMaxCasesAvailableForOffer } from '../../hooks/useMaxCasesAvailableForOffer';
import { logError } from '../../utils/logger';
import { AccountType } from '../../types/AccountType';
import { useOrderForm } from './useOrderForm';
import { selectFormState } from './selectors';
import { CreateOrderFormProps, EditOrderFormProps } from './types';
import { calculateCost, userHasSufficientFundsToCoverOrder } from './utils';
import { getValidationSchema } from './helpers';

export default function OrderForm(props: CreateOrderFormProps | EditOrderFormProps): JSX.Element {
  const { assetId, onSubmit, onError, loading, disableSubmit, tradeType, mode, formState, showFees = true } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const {
    tradingHeadroom,
    fundsOnAccount,
    userDetails,
    order,
    loading: loadingOrderForm,
    error: failedToLoadOrderForm,
  } = useOrderForm({
    skipOrder: mode !== Mode.Edit,
    orderId: (props as EditOrderFormProps).orderId,
  });

  const {
    maximumCasesAvailableForOffer,
    error: failedToLoadMaxCases,
    loading: loadingMaxCases,
  } = useMaxCasesAvailableForOffer({
    assetId,
    excludeOrders: [(props as EditOrderFormProps).orderId],
    skip: tradeType !== 'offer',
  });

  const [lowBalanceError, setLowBalanceError] = useState(false);
  const [vat, setVat] = useState<number>(0);
  const [totalUnitPrice, setTotalUnitPrice] = useState(0);
  const [totalPricePlusFee, setTotalPricePlusFee] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);

  React.useEffect(() => {
    if (
      (mode === Mode.Edit && !order && !loadingOrderForm && !formState) ||
      (tradeType === 'offer' && failedToLoadMaxCases) ||
      failedToLoadOrderForm
    ) {
      logError({
        error: new Error('Crucial data for the order form did not load'),
        filename: 'OrderForm',
        additionalInfo: {
          failedToLoadOrderForm: JSON.stringify(failedToLoadOrderForm),
          failedToLoadMaxCases: JSON.stringify(failedToLoadMaxCases),
        },
        tags: {
          userFlow: 'trade',
        },
      });
      onError();
    }
  }, [mode, onError, failedToLoadOrderForm, failedToLoadMaxCases, order, loadingOrderForm, tradeType, formState]);

  const validationSchema = getValidationSchema(t, tradeType, maximumCasesAvailableForOffer);
  const initialValues = selectFormState({ mode, order, initialState: formState });

  const formik = useFormik<yup.InferType<typeof validationSchema>>({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (formData) => {
      const hasSufficientFundsToCoverOrder =
        mode === Mode.Edit
          ? userHasSufficientFundsToCoverOrder({
              mode,
              tradeType,
              tradingHeadroom,
              newCostBreakdown: { totalPrice: totalUnitPrice, totalPricePlusFee, transactionFee, vat },
              originalCostBreakdown: calculateCost({
                price: order?.price,
                quantity: order?.outstandingQuantity,
                tradeType,
                showFees,
              }),
            })
          : userHasSufficientFundsToCoverOrder({
              mode,
              tradeType,
              tradingHeadroom,
              costBreakdown: { totalPrice: totalUnitPrice, totalPricePlusFee, transactionFee, vat },
            });

      if (!hasSufficientFundsToCoverOrder) {
        setLowBalanceError(true);
        return;
      }

      onSubmit({
        expirationDate: formData.expirationDate,
        price: Number(formData.price),
        quantity: Number(formData.quantity),
        totalUnitPrice,
        totalPricePlusFee,
        transactionFee,
        vat,
      });
    },
    validationSchema,
  });

  React.useEffect(() => {
    const price = Number(formik.values.price);
    const quantity = Number(formik.values.quantity);

    const {
      totalPrice: _totalPrice,
      totalPricePlusFee: _totalPricePlusFee,
      transactionFee: _transactionFee,
      vat: _vat,
    } = calculateCost({ price, quantity, tradeType, showFees })!;
    setVat(_vat);
    setTotalUnitPrice(_totalPrice);
    setTotalPricePlusFee(_totalPricePlusFee);
    setTransactionFee(_transactionFee);
  }, [formik.values.price, formik.values.quantity, tradeType, showFees]);

  React.useEffect(() => {
    if (lowBalanceError) {
      if (totalPricePlusFee < tradingHeadroom) {
        setLowBalanceError(false);
      }
    }
  }, [totalPricePlusFee, tradingHeadroom, lowBalanceError]);

  const _individualUnitPriceIncludingFee = calculateFee(Number(formik.values.price), Fees.Standard);
  const _feeForIndividualUnit = _individualUnitPriceIncludingFee - Number(formik.values.price);

  const fundsOnAccountExceeded =
    !loadingOrderForm &&
    userDetails?.accountType !== AccountType.Whale &&
    tradeType !== 'offer' &&
    !isNull(fundsOnAccount) &&
    (showFees
      ? fundsOnAccount < _individualUnitPriceIncludingFee + _feeForIndividualUnit * 0.2
      : fundsOnAccount < Number(formik.values.price));

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    formik.handleChange(e);
  };
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const { value, selectionStart } = e.target as HTMLInputElement;
    // not allow start with 0 when empty
    const startWithZero = value === '' && e.key === '0';
    // not allow start with 0 when not empty
    const startWithZeroWithValue = value !== '' && selectionStart === 0 && e.key === '0';
    // only allow 0-9, Backspace Tab Arrow keys
    const notAllowKeyDown = !/\d|(Backspace)|(Tab)|(Arrow(Down|Up|Left|Right))/.test(e.key);
    if (startWithZero || startWithZeroWithValue || notAllowKeyDown) {
      e.preventDefault();
    }
  };
  const disableBtn =
    fundsOnAccountExceeded ||
    formik.isSubmitting ||
    !formik.isValid ||
    loadingOrderForm ||
    loading ||
    disableSubmit ||
    lowBalanceError;
  return loadingOrderForm || (tradeType === 'offer' && loadingMaxCases) ? (
    <Skeleton width="100%" height="360px" variant="rectangular" aria-label="loading-skeleton" />
  ) : (
    <form className={classes.container} onSubmit={formik.handleSubmit}>
      <div className={classes.fieldsContainer}>
        <div className={classes.priceInputContainer}>
          <Typography className={classes.fieldLabel} customVariant="subtitle1Bold">
            {t('product:price')}
          </Typography>
          <CurrencyTextField
            className={classes.field}
            data-testid="price"
            name="price"
            value={formik.values.price}
            placeholder={t('product:detailsModal.pricePlaceholder')}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={formik.handleBlur}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
          />
          {(lowBalanceError || fundsOnAccountExceeded) && (
            <Link to="/wallet" className={classes.link}>
              <Alert variant="filled" className={classes.alert} severity="error">
                <AlertTitle className={classes.bold}>{t('product:detailsModal.error.attention')}</AlertTitle>
                {t(`product:detailsModal.${tradeType}.lowBalance` as const)}
              </Alert>
            </Link>
          )}
        </div>
        <div className={classes.fieldContainer}>
          <Typography className={classes.fieldLabel} customVariant="subtitle1Bold">
            {t('product:unitSize')}
          </Typography>
          <UnitSizeSelect assetId={assetId} />
        </div>
        <div className={classes.fieldContainer}>
          <Typography className={classes.fieldLabel} customVariant="subtitle1Bold">
            {t('product:detailsModal.quantity')}
          </Typography>
          <TextField
            id="quantity"
            name="quantity"
            data-testid="quantity"
            className={classes.field}
            value={formik.values.quantity}
            placeholder={t('product:detailsModal.quantityPlaceholder')}
            variant="outlined"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={formik.handleBlur}
            error={formik.touched.quantity && Boolean(formik.errors.quantity)}
            helperText={formik.touched.quantity && formik.errors.quantity}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div>
          <Typography className={classes.fieldLabel} customVariant="subtitle1Bold">
            {t('product:detailsModal.expiration')}
          </Typography>
          <Select
            placeholder={t('product:detailsModal.expirationPlaceholder')}
            optionId={formik.values.expirationDate}
            setOption={(e) => formik.setFieldValue('expirationDate', e)}
            options={selectExpirationDateOptions()}
            inputLabel={t('product:detailsModal.expiration')}
            inputClass={classes.expirationDateSelect}
            inlineLabel
          />
        </div>
      </div>
      <CostBreakdownComponent
        className={classes.costBreakdown}
        variant={tradeType}
        unitPrice={Number(formik.values.price)}
        totalPricePlusFees={totalPricePlusFee}
        transactionFee={transactionFee}
        totalUnitPrice={totalUnitPrice}
        vat={vat}
        showFees={showFees}
      />
      {tradeType === 'bid' && (
        <Typography className={classes.disclaimerText}>
          {t('common:disclaimer.text1')}{' '}
          <a href="https://www.cultx.com/delivery" className={classes.linkText} target="_blank" rel="noreferrer">
            {t('common:disclaimer.link')}
            {'. '}
          </a>
          {t('common:disclaimer.text2')}
        </Typography>
      )}
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={disableBtn}
      >
        {t(`product:detailsModal.${tradeType}.reviewButton` as const)}
      </Button>
    </form>
  );
}
