import { ApolloError } from '@apollo/client';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import PlaceholderImage from '../../assets/images/stub-wine-image.png';
import getImageUrl from '../../utils/getImageUrl';
import { massageToNull } from '../../utils/massageToNull';
import { WatchListItem } from '../../__generated__/graphql';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

export type WatchlistFacets = Omit<WatchlistAsset, 'marketData' | 'unitSize'>;

export type WatchlistAsset = {
  assetId: number;
  name: string;
  vintage: number;
  appellation: string;
  /**
   * In the search results, we are going to get back all manner
   * of regions, even those we don't recognise within Zellar (see RegionName).
   * We still want to display the region name we are given from the search query,
   * so there is no need to map it to a 'Zellar' region at this level.
   */
  region: string;
  score: number | null;
  marketValue: number;
  unitSize: string;
  unitCount: number;
  highestBid: number | null;
  lowestOffer: number | null;
  performancePercentageDelta: number | null;
  performanceValueDelta: number | null | undefined;
  marketData: Record<string, number>;
  imageFilename: string;
};

export function selectWatchlistItems(rawItems: WatchListItem[]): WatchlistAsset[] {
  return rawItems.map((r) => ({
    assetId: r.asset.id,
    name: r.asset.vintage.wine.name,
    vintage: r.asset.vintage.vintage,
    appellation: r.asset.vintage.wine.country ?? '',
    region: r.asset.vintage.wine.region?.name ?? '',
    score: r.calculatedMarketData.combinedScore || null,
    marketValue: r.asset.marketValue ?? 0,
    unitCount: r.asset.unitCount,
    unitSize: r.asset.unitSize,
    highestBid: massageToNull(r.asset.spread?.highestBid?.price),
    lowestOffer: massageToNull(r.asset.spread?.lowestOffer?.price),
    performancePercentageDelta: !isNullOrUndefined(r.calculatedMarketData.performance.percentageDifference)
      ? parseFloat(r.calculatedMarketData.performance.percentageDifference.toFixed(2))
      : null,
    performanceValueDelta: r.calculatedMarketData.performance.valueDifference,
    marketData: {},
    imageFilename: r.asset.vintage.imageFileName
      ? getImageUrl(r.asset.vintage.imageFileName, { height: 200 })
      : PlaceholderImage,
  }));
}

export function selectError(error: ApolloError | undefined): string | null {
  if (isUndefined(error)) {
    return null;
  }

  return error.message;
}
