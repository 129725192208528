import { FiltersType } from '../filters/FilterView';

export const applyFilters = <T>(dataSource: T[], filterConfig: FiltersType<T> | null) => {
  if (!filterConfig) return { isFiltered: false, filteredDataSource: [] };

  let source = [...dataSource] as Record<string, string>[];

  let isFiltered = false;

  Object.keys(filterConfig).forEach((key) => {
    if (filterConfig[key].previewSelected.length > 0 && !isFiltered) isFiltered = true;

    if (filterConfig[key].previewSelected.length > 0 && filterConfig[key].customFilterFunction) {
      source = filterConfig[key].customFilterFunction?.(filterConfig, source as T[]) as Record<string, string>[];
    } else if (filterConfig[key].previewSelected.length > 0) {
      source = source.filter((x) => filterConfig[key].previewSelected.includes(String(x[key])));
    }
  });

  return { isFiltered, filteredDataSource: source as T[] };
};
