import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    paddingRight: '1.5rem',

    [theme.breakpoints.up('lg')]: {
      /**
       * Emulate the divider, it's an absolute
       * nightmare trying to get <Divider /> to
       * work with <Grid /> without completely
       * messing up everything we have so far.
       */
      borderWidth: '0 1px 0 0', // right side
      borderColor: theme.palette.dividers.primary,
      borderStyle: 'solid',
    },
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
    },
  },
  tradingSection: {
    [theme.breakpoints.between('sm', 'lg')]: {
      /**
       * Emulate the divider, it's an absolute
       * nightmare trying to get <Divider /> to
       * work with <Grid /> without completely
       * messing up everything we have so far.
       */
      borderWidth: '0 1px 0 0', // right side
      borderColor: theme.palette.dividers.primary,
      borderStyle: 'solid',
    },
  },
  guestProductTradingSection: {
    height: theme.spacing(30),
    minHeight: '100%',
    background: theme.palette.grey[100],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
}));
