import { Box, Tabs, Tab, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import RegistrationForm from '../../components/RegistrationForm';
import { useStyles } from './styles';
import LoginComp from '../Login/components/loginComp';
import { UrlPath } from '../../types/Enums';
import SEO from '../../components/SEO';

// import { ReactComponent as CultXLogo } from '../../assets/icons/cultx_logo.svg';
// import CultXLogoMemo from '../../components/CultXLogo';

enum TabLabel {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
}

const titlesDescs = [
  {
    title: 'Login to Your Account | CultX',
    desc: 'Login to CultX to access our extensive fine wine selection. Buy, bid, offer, or view your portfolio as you grow your fine wine collection with CultX. ',
    type: UrlPath.LOGIN,
  },
  {
    title: 'Register for Your Free Account | CultX',
    desc: 'Join CultX to unlock access to a vast collection of fine wines. Register now to buy, bid, or offer, and start your fine wine investment and trading journey today.',
    type: UrlPath.REGISTER,
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const AuthTabs: React.FC<{
  tab?: UrlPath;
  onTabChange?: (tab: UrlPath) => void;
}> = ({ tab = UrlPath.LOGIN, onTabChange = undefined }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState<number>(0);
  const { t } = useTranslation();
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const [userHasRegistered, setUserHasRegistered] = React.useState(false);

  function onUserRegisteredSuccessfully() {
    setUserHasRegistered(true);
  }

  useEffect(() => {
    const currentPath = tabValue === 0 ? UrlPath.LOGIN : UrlPath.REGISTER;
    if (onTabChange) {
      onTabChange(currentPath);
    }
    window.history.replaceState(null, '', currentPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  useEffect(() => {
    if (tab === UrlPath.REGISTER) {
      setTabValue(1);
    } else {
      setTabValue(0);
    }
  }, [tab]);

  const urlPath = titlesDescs[tabValue].type;
  const selectedTitleDesc = titlesDescs[tabValue];

  return (
    <HelmetProvider>
      <Box className={`${classes.formBox} ${classes.column}`}>
        {userHasRegistered ? (
          <div
            style={{ display: 'flex', paddingTop: '30px', paddingRight: '10px', paddingLeft: '10px' }}
            className={`${classes.container} ${classes.tabPanel}`}
          >
            <Typography variant="h3" textAlign="center">
              {t('account:register.success')}
            </Typography>
          </div>
        ) : (
          <>
            {/* Tabs Header */}
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="auth tabs"
              centered
              sx={{
                width: '100%',
                height: '48px',

                borderBottom: 1,
                borderBottomColor: 'red',
                borderColor: 'divider',
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 'bold',
                  background: '#B8B8B8',
                  color: '#8A809A',
                },
                '& .Mui-selected': {
                  background: '#FFFFFF',
                  color: 'black',
                  fontWeight: 'bold',
                },
              }}
            >
              <Tab label={TabLabel.LOGIN} style={{ width: '50%', borderTopLeftRadius: 5 }} {...a11yProps(0)} />
              <Tab label={TabLabel.REGISTER} style={{ width: '50%', borderTopRightRadius: 5 }} {...a11yProps(1)} />
            </Tabs>

            {/* Tab Content */}
            <TabPanel value={tabValue} index={0} className={`${classes.container} ${classes.tabPanel}`}>
              <LoginComp />
            </TabPanel>
            <TabPanel value={tabValue} index={1} className={`${classes.container} ${classes.tabPanel}`}>
              <RegistrationForm onUserRegisteredSuccessfully={onUserRegisteredSuccessfully} />
            </TabPanel>
          </>
        )}
      </Box>
      <SEO title={`${selectedTitleDesc.title}`} description={`${selectedTitleDesc.desc}`} path={urlPath} />
    </HelmetProvider>
  );
};

export default AuthTabs;
