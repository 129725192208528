import React, { FC, ReactNode, useEffect, useRef, useState, useCallback } from 'react';
import { ReactComponent as ChevronUp } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';
import { classNames } from '../../utils/classname';

interface DropdownItem {
  id: string;
  value: string;
  content: ReactNode;
  text?: string;
}

interface ItemsConfig {
  items?: DropdownItem[];
  displayItems?: DropdownItem[];
  start: number;
  end: number;
}

interface DropdownPropType {
  value?: string;
  placeholder?: string;
  onItemSelect?: (item: DropdownItem) => void;
  items?: DropdownItem[];
  className?: string;
  iconClassName?: string;
  style?: Record<string, unknown>;
  valueText?: string;
  header?: ReactNode;
  footer?: ReactNode;
  itemClassName?: string;
  onOpen?: () => void;
  children?: ReactNode;
  containerClassName?: string;
  itemsContainerClassName?: string;
  itemsWrapperClassName?: string;
  autoClose?: boolean;
  open?: boolean;
  isDisabled?: boolean;
  valueTemplate?: ReactNode;
  itemWrapperStyle?: Record<string, unknown>;
  id: string;
  isActive?: boolean;
}

const InfiniteScrollDropdown: FC<DropdownPropType> = ({
  id,
  onItemSelect,
  items,
  className,
  value,
  placeholder,
  iconClassName,
  style,
  valueText,
  valueTemplate,
  header,
  footer,
  containerClassName,
  itemClassName,
  itemsContainerClassName,
  itemsWrapperClassName,
  onOpen,
  children,
  open,
  isDisabled = false,
  autoClose = true,
  itemWrapperStyle,
  isActive,
}) => {
  const divRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const START = 0;
  const END = 20;
  const [itemsConfig, setItemsConfig] = useState<ItemsConfig>({
    items: [],
    displayItems: [],
    start: START,
    end: END,
  });

  const handleScroll = useCallback(() => {
    const div = divRef.current as unknown as HTMLDivElement;
    if (div.scrollTop + div.clientHeight + 350 >= div.scrollHeight) {
      setItemsConfig((prev) => ({
        ...prev,
        displayItems: [...(prev.items ?? [])].slice(0, prev.end + END),
        end: prev.end + END,
      }));
    }
  }, []);

  useEffect(() => {
    if (items)
      setItemsConfig((prev) => ({
        ...prev,
        items: items,
        displayItems: [...(items ?? [])].slice(0, prev.end),
      }));
  }, [items]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.id === id) {
        setIsOpen((prev) => !prev);
      } else {
        setIsOpen(false);
      }
    };

    const div = divRef.current as unknown as HTMLDivElement;
    if (div) {
      div.addEventListener('scroll', handleScroll);
      document.addEventListener('click', handleClick);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
        document.removeEventListener('click', handleClick);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef.current, divRef, id]);

  return (
    <div className={`tw-relative  tw-text-left ${containerClassName || ''}`.trim()}>
      <button
        type="button"
        id={id}
        onClick={() => {
          if (onOpen && items && items.length > 0) {
            onOpen();
          }
        }}
        style={style}
        className={classNames(
          `${children ? '' : 'tw-relative tw-bg-white tw-border  tw-outline-none'}  ${
            isDisabled ? `tw-btn-disabled` : ''
          }`,
          `${className || ''}`,
        )}
      >
        {children || (
          <>
            {isActive && (
              <div className="tw-rounded-full tw-h-[6px] tw-w-[6px]  tw-min-h-[6px]  tw-min-w-[6px]  tw-mr-1 tw-bg-[#1FE85C] tw-pointer-events-none" />
            )}

            {valueTemplate || valueText || value || placeholder || ''}

            {!open && items && items.length > 0 && (
              <ChevronDown
                className={`tw--mr-1 tw-ml-2 tw-pointer-events-none  ${iconClassName || ''}  ${
                  isDisabled ? 'tw-opacity-30' : 'tw-opacity-100'
                }`}
                aria-hidden="true"
              />
            )}
            {!isDisabled && items && items.length > 1 && open && (
              <ChevronUp
                className={`tw--mr-1 tw-ml-2 tw-pointer-events-none  ${iconClassName || ''}`}
                aria-hidden="true"
              />
            )}
          </>
        )}
      </button>

      {(header !== undefined || footer !== undefined || (items && items.length > 0)) && (
        <div className={`  ${isOpen ? 'tw-visible' : 'tw-hidden'}`}>
          <div
            hidden={!isOpen}
            style={{
              ...(itemWrapperStyle || {}),
              background: '#f5f5f5',
              marginTop: 10,
              boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
            }}
            {...(!autoClose && { static: true })}
            className={`tw-absolute tw-z-10 mt-2  tw-origin-top-right  tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none ${
              itemsWrapperClassName || ''
            }`.trim()}
          >
            {header && <div>{header}</div>}
            <div className={`tw-pb-1 ${itemsContainerClassName || ''}`.trim()} ref={divRef}>
              {itemsConfig.displayItems &&
                itemsConfig.displayItems.length > 0 &&
                itemsConfig.displayItems.map((item) => {
                  return (
                    <div
                      style={{ margin: '1px' }}
                      role="button"
                      tabIndex={0}
                      key={item.id}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          if (item.value !== value && onItemSelect) onItemSelect(item);
                        }
                      }}
                      onClick={() => {
                        if (item.value !== value && onItemSelect) onItemSelect(item);
                      }}
                      className={`
                        tw-cursor-pointer tw-bg-white  tw-outline-none tw-border-none tw-text-gray-900' ${
                          itemClassName || ''
                        }`.trim()}
                    >
                      {item.content}
                    </div>
                  );
                })}
            </div>

            {footer && <div>{footer}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default InfiniteScrollDropdown;
