import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(8),
    width: '85%',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },

  column: {
    flex: 1,
    width: '45%',
    maxWidth: '700px',
  },

  marketing: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),

    [theme.breakpoints.up('lg')]: {
      padding: '50px 75px',
    },
  },

  marketingWidgets: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },

  formBox: {
    width: '100%',
    marginBottom: '20px',
    padding: '50px 25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-evenly',
    boxShadow: 'none',

    [theme.breakpoints.up('md')]: {
      border: `1px solid ${theme.palette.dividers.primary}`,
      borderRadius: 20,
      background: theme.palette.background.default,
      padding: '25px 50px',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '50px 75px',
    },
  },

  links: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
  },

  headingTextV2: {
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '40.8px',
    textAlign: 'left',
  },

  subHeadingTextV2: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '21.6px',
  },

  footerText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#FFFFFF',
  },
  copyright: {
    color: '#8A809A',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.4px',
    textAlign: 'left',
  },
  splashContainer: {
    width: '100%',
  },
}));

export { useStyles };
