import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useAppLayoutContext } from '../components/AppLayout/Context';
import { SortDirection } from '../components/Table/types';
import { ActionEventType } from '../types/Enums';

const PAGE_SIZE_VARIANTS = [20, 60, 90];

interface UseTableControlsProps<Facets> {
  pageSizeVariants?: number[];
  defaultFacet: keyof Facets;
  defaultSortDirection?: SortDirection;
}

interface UseTableControls<Facets> {
  pageSize: number;
  page: number;
  from: number;
  sortFacet: keyof Facets;
  sortDirection: SortDirection;
  selectedRowIds: Array<string>;
  handleChangePage: (_: unknown, newPage: number) => void;
  handleChangeResultsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSortUpdated: (key: keyof Facets, direction?: SortDirection) => void;
  handleRowToggle: (rowId: string) => void;
  clearSelectedRows: () => void;
  resetState: () => void;
  setSortFacet: React.Dispatch<React.SetStateAction<keyof Facets>>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useTableControls<Facets>({
  defaultFacet,
  defaultSortDirection = 'asc',
  pageSizeVariants = PAGE_SIZE_VARIANTS,
}: UseTableControlsProps<Facets>): UseTableControls<Facets> {
  const { desktopOnly } = useFlags();
  const { dispatch: appLayoutDispatch } = useAppLayoutContext();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(pageSizeVariants[0]);
  const from = React.useMemo(() => page * pageSize, [page, pageSize]);
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(defaultSortDirection);
  const [sortFacet, setSortFacet] = React.useState<keyof Facets>(defaultFacet);
  const [selectedRowIds, setSelectedRowIds] = React.useState<Array<string>>([]);

  function handleChangePage(_: unknown, newPage: number): void {
    if (!desktopOnly) {
      appLayoutDispatch({ type: ActionEventType.SCROLL_TO_EVENT, payload: { x: 0, y: 0 } });
    }
    setPage(newPage);
  }

  function handleChangeResultsPerPage(event: React.ChangeEvent<HTMLInputElement>): void {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleSortUpdated(key: keyof Facets, direction?: SortDirection): void {
    if (!direction) {
      if (key !== sortFacet) {
        // a different facet has been selected
        setSortFacet(key);
        setSortDirection('asc');
      } else {
        // it's just the sort direction that has changed
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      }
    } else {
      setSortFacet(key);
      setSortDirection(direction);
    }
  }

  function resetState(): void {
    setPage(0);
    setPageSize(pageSizeVariants[0]);
    setSortDirection('asc');
    setSortFacet(defaultFacet);
    setSelectedRowIds([]);
  }

  function handleRowToggle(rowId: string): void {
    if (selectedRowIds.includes(rowId)) {
      setSelectedRowIds(selectedRowIds.slice().splice(selectedRowIds.indexOf(rowId), 1));
    } else {
      setSelectedRowIds([...selectedRowIds, rowId]);
    }
  }

  function clearSelectedRows(): void {
    setSelectedRowIds([]);
  }

  return {
    pageSize,
    page,
    from,
    setSortFacet,
    sortFacet,
    sortDirection,
    selectedRowIds,
    handleChangePage,
    handleChangeResultsPerPage,
    handleSortUpdated,
    handleRowToggle,
    clearSelectedRows,
    resetState,
  };
}
