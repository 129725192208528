/* eslint-disable no-nested-ternary */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPlaceholder from '../ErrorPlaceholder';
import EmptyPlaceholder from '../EmptyPlaceholder';
import { AssetInstance } from '../../__generated__/graphql';
import BasicCell from '../Table/Cells/Basic';
import { formatter } from '../../utils/currencyFormatter';
import { humanReadableDate } from '../../utils/humanReadableDate';
import RowSkeleton from '../TableElements/RowSkeleton';
import { COLUMN_COUNT } from './types';
import TableCellHead from '../TableElements/TableCellHead';
import Checkbox from '../Checkbox';
import { useSelectedRowsContext } from '../../context/selectedRowsContext';
import CustomCell from '../Table/Cells/Custom';
import Pill from '../Pill';
import { colours } from '../../theme/light/palette';

interface SubTableProps {
  instances: AssetInstance[] | undefined;
  loading: boolean;
  error: string | null;
  lastIndexAvailableForOffer: number;
  onTransferCheckboxChanged: (subRowId: string, assetId: number | undefined) => void;
}

export default function CultXPortfolioSubTable({
  instances,
  loading,
  error,
  lastIndexAvailableForOffer,
  onTransferCheckboxChanged,
}: SubTableProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const { state, dispatch } = useSelectedRowsContext();

  function handleTransferSubCheckboxChanged(
    e: React.ChangeEvent<HTMLInputElement>,
    subRowId: string,
    assetId: number | undefined,
  ): void {
    if (e.target.checked) {
      if (!instances) {
        return;
      }
      onTransferCheckboxChanged(subRowId, assetId);
      // find the parent and add it.
      const parent = instances.find((r) => r.assetId === assetId)!;
      dispatch({
        type: 'rowTransferChecked',
        payload: {
          type: 'ParentAsset',
          id: parent.assetId,
          assetId: parent.assetId,
        },
      });
    } else {
      dispatch({ type: 'rowTransferUnchecked', payload: assetId });
      dispatch({ type: 'rowTransferUnchecked', payload: subRowId });
    }
  }

  return (
    <TableContainer>
      <Table aria-label="cultx portfolio sub table">
        <TableHead>
          <TableRow>
            <TableCellHead hideSortButton />
            <TableCellHead title={t('portfolio:table.headers.location')} hideSortButton />
            <TableCellHead title={t('portfolio:table.headers.purchasePrice')} hideSortButton />
            <TableCellHead title={t('portfolio:table.headers.marketValuation')} hideSortButton />
            <TableCellHead title={t('portfolio:table.headers.duty')} hideSortButton />
            <TableCellHead title={t('portfolio:table.headers.entryDate')} hideSortButton />
            <TableCellHead hideSortButton />
          </TableRow>
        </TableHead>
        <TableBody sx={{ background: theme.palette.grey[50] }}>
          {loading ? (
            <RowSkeleton columnCount={COLUMN_COUNT} rowCount={3} />
          ) : error ? (
            <TableCell align="center" colSpan={COLUMN_COUNT}>
              <ErrorPlaceholder error={error} />
            </TableCell>
          ) : !instances?.length ? (
            <TableCell align="center" colSpan={COLUMN_COUNT}>
              <EmptyPlaceholder label={t('common:empty')} />
            </TableCell>
          ) : (
            instances.map((instance, index) => (
              <TableRow key={instance.id}>
                <TableCell width={36}>
                  {state.selectTransferModeActive && (
                    <Checkbox
                      inputProps={{ 'aria-label': 'sub-table-checkbox' }}
                      checked={
                        instance.transferRequested ||
                        state.selectedTransferedRows.map((x) => x.id).includes(instance.id)
                      }
                      color="primary"
                      onChange={(e) => {
                        handleTransferSubCheckboxChanged(e, instance.id, instance.assetId);
                      }}
                      disabled={instance.transferRequested || index > lastIndexAvailableForOffer}
                    />
                  )}
                </TableCell>
                <BasicCell mainContent={instance.location} />
                <BasicCell mainContent={formatter.format(instance.purchasePrice)} />
                <BasicCell
                  mainContent={instance.valuation ? formatter.format(instance.valuation) : t('product:notAvailable')}
                />
                <BasicCell mainContent={instance.bondedStatus} />
                <BasicCell mainContent={humanReadableDate(instance.entryDate)} />
                <CustomCell
                  content={
                    instance.transferRequested && (
                      <Pill colour={colours.brightOrange} text={t('portfolio:transfer.requested')} />
                    )
                  }
                />
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
