import { ApolloError, useQuery } from '@apollo/client';
import { graphql } from '../__generated__';
import { DoesAssetExistInAnyHoldingsQuery } from '../__generated__/graphql';

const DOES_ASSET_EXIST_IN_ANY_HOLDINGS = graphql(`
  query DoesAssetExistInAnyHoldings($assetId: Int!) {
    isAssetExistInAnyHoldings(assetId: $assetId)
  }
`);

type UseDoesAssetExistInAnyHoldingsReturnType = {
  data?: DoesAssetExistInAnyHoldingsQuery;
  error?: ApolloError;
  loading: boolean;
};

type UseDoesAssetExistInAnyHoldingsProps = {
  assetId: number;
};

export default function useDoesAssetExistInAnyHoldings({
  assetId,
}: UseDoesAssetExistInAnyHoldingsProps): UseDoesAssetExistInAnyHoldingsReturnType {
  const { data, loading, error } = useQuery(DOES_ASSET_EXIST_IN_ANY_HOLDINGS, {
    variables: {
      assetId,
    },
  });
  return { data, loading, error };
}
