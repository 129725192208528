import { Namespace, Resources, TFunction } from 'react-i18next';
import React from 'react';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { uuid } from '../../utils/uuid';
import { WatchlistAsset, WatchlistFacets } from '../../views/WatchList/selectors';
import { SortOption } from '../Table/SortMenu';
import {
  ActionCallback,
  BasicCell,
  CustomCell,
  Row,
  ScoreCell,
  SortDirection,
  SparklineCell,
  UnitSizeCell,
  WineNameCell,
} from '../Table/types';
import TradingTile from '../TradingTile';
import isUndefined from '../../utils/isUndefined';
import { makeUrlSafe } from '../../utils/common';
import { UrlPath } from '../../types/Enums';

/**
 * @deprecated
 */
export interface TableData extends Row {
  wineName: WineNameCell;
  vintage: BasicCell;
  unitSize: UnitSizeCell;
  score: ScoreCell;
  marketValue: BasicCell;
  performance: SparklineCell;
  highestBid: CustomCell;
  lowestOffer: CustomCell;
}

interface SelectWatchlistTableDataProps {
  onOfferClicked: ActionCallback;
  onBidClicked: ActionCallback;
  onBuyNowClicked: ActionCallback;
  onDeleteClicked: ActionCallback;
  watchlistItems: WatchlistAsset[];
  clickableTradeTiles13816?: boolean;
}

/**
 * @deprecated
 */
export function selectWatchlistTableData({
  onBidClicked,
  onDeleteClicked,
  onOfferClicked,
  onBuyNowClicked,
  watchlistItems,
  clickableTradeTiles13816,
}: SelectWatchlistTableDataProps): TableData[] {
  return watchlistItems.map((item) => {
    const HighestBidTradingTile = clickableTradeTiles13816 ? (
      <TradingTile mode="bid" value={item.highestBid} onClick={() => onBidClicked(item.assetId.toString())} />
    ) : (
      <TradingTile mode="bid" value={item.highestBid} unitCount={item.unitCount} unitSize={item.unitSize} />
    );

    const LowestOfferTradingTile = clickableTradeTiles13816 ? (
      <TradingTile
        mode="offer"
        value={item.lowestOffer}
        onClick={!isUndefined(item.lowestOffer) ? () => onBuyNowClicked(item.assetId.toString()) : undefined}
      />
    ) : (
      <TradingTile mode="offer" value={item.lowestOffer} unitCount={item.unitCount} unitSize={item.unitSize} />
    );

    const { assetId, name: wineName, vintage, unitCount, unitSize } = item;
    const unitsSizeStr = `${unitCount}x${unitSize}`;
    const safeWineName = makeUrlSafe(wineName);
    const pageSlug = `${assetId}_${safeWineName}_${vintage}_${unitsSizeStr}`;
    const prodPageUrl = `${UrlPath.PRODUCT_PAGE}/${pageSlug}`;

    return {
      wineName: {
        appellation: item.appellation,
        region: item.region,
        wineName: item.name,
        link: `${prodPageUrl}`,
      },
      vintage: { mainContent: item.vintage },
      unitSize: {
        unitCount: item.unitCount,
        unitSize: item.unitSize,
      },
      score: {
        score: item.score,
      },
      marketValue: {
        mainContent: formatterWholeNumber.format(item.marketValue),
      },
      highestBid: {
        content: HighestBidTradingTile,
      },
      lowestOffer: {
        content: LowestOfferTradingTile,
      },
      performance: {
        data: item.marketData,
        deltaNum: item.performanceValueDelta,
        deltaPct: item.performancePercentageDelta,
      },
      rowId: item.assetId.toString(),
      actions: {
        trade: {
          onBidClicked,
          onOfferClicked,
          onBuyClicked: onBuyNowClicked,
          assetId: item.assetId,
          condensed: true,
        },
        delete: {
          onDeleteClicked,
        },
      },
    };
  });
}

export function getSortOptions(
  sortDirection: SortDirection,
  activeSortKey: keyof WatchlistFacets,
  t: TFunction<Namespace<keyof Resources>>,
): SortOption<keyof WatchlistFacets>[] {
  const sortOptions: SortOption<keyof WatchlistFacets>[] = [
    {
      id: uuid(),
      direction: 'desc',
      key: 'name',
      active: activeSortKey === 'name' && sortDirection === 'desc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.zToA'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'name',
      active: activeSortKey === 'name' && sortDirection === 'asc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.aToZ'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'region',
      active: activeSortKey === 'region' && sortDirection === 'desc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.zToA'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'region',
      active: activeSortKey === 'region' && sortDirection === 'asc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.aToZ'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'score',
      active: activeSortKey === 'score' && sortDirection === 'desc',
      primaryLabel: t('search:filters.score'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'score',
      active: activeSortKey === 'score' && sortDirection === 'asc',
      primaryLabel: t('search:filters.score'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'vintage',
      active: activeSortKey === 'vintage' && sortDirection === 'desc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.newestToOldest'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'vintage',
      active: activeSortKey === 'vintage' && sortDirection === 'asc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.oldestToNewest'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'marketValue',
      active: activeSortKey === 'marketValue' && sortDirection === 'desc',
      primaryLabel: t('search:filters.marketValue'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'marketValue',
      active: activeSortKey === 'marketValue' && sortDirection === 'asc',
      primaryLabel: t('search:filters.marketValue'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'unitCount',
      active: activeSortKey === 'unitCount' && sortDirection === 'desc',
      primaryLabel: t('trade:units'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'unitCount',
      active: activeSortKey === 'unitCount' && sortDirection === 'asc',
      primaryLabel: t('trade:units'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'performancePercentageDelta',
      active: activeSortKey === 'performancePercentageDelta' && sortDirection === 'desc',
      primaryLabel: t('search:filters.performance'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'performancePercentageDelta',
      active: activeSortKey === 'performancePercentageDelta' && sortDirection === 'asc',
      primaryLabel: t('search:filters.performance'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'highestBid',
      active: activeSortKey === 'highestBid' && sortDirection === 'desc',
      primaryLabel: t('product:trading.highestBid'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'highestBid',
      active: activeSortKey === 'highestBid' && sortDirection === 'asc',
      primaryLabel: t('product:trading.highestBid'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'lowestOffer',
      active: activeSortKey === 'lowestOffer' && sortDirection === 'desc',
      primaryLabel: t('product:trading.lowestOffer'),
      secondaryLabel: t('search:sort.highToLow'),
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'lowestOffer',
      active: activeSortKey === 'lowestOffer' && sortDirection === 'asc',
      primaryLabel: t('product:trading.lowestOffer'),
      secondaryLabel: t('search:sort.lowToHigh'),
    },
  ];

  return sortOptions;
}
