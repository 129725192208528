/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import clsx from 'clsx';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import { useStyles, ButtonCard } from './styles';
import { TradingTileMode } from './types';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

type TradingTilePropsBase = {
  mode: TradingTileMode;
  className?: string;
  value: number | null | undefined;
  style?: React.CSSProperties;
};

type TradingTileBasicProps = TradingTilePropsBase & {
  onClick?: () => void;
};

type TradingTileUnitSizeProps = TradingTilePropsBase & {
  unitSize?: string;
  unitCount?: number;
};

type TradingTileCreatedDateProps = TradingTilePropsBase & {
  createdDate?: string;
};

type TradingTileProps = TradingTileCreatedDateProps | TradingTileUnitSizeProps | TradingTileBasicProps;

function isTradingTileCreatedDateProps(arg: TradingTileProps): arg is TradingTileCreatedDateProps {
  return !isUndefined((arg as TradingTileCreatedDateProps).createdDate);
}

function isTradingTileUnitSizeProps(arg: TradingTileProps): arg is TradingTileUnitSizeProps {
  return (
    !isUndefined((arg as TradingTileUnitSizeProps).unitSize) &&
    !isUndefined((arg as TradingTileUnitSizeProps).unitCount)
  );
}

export default function TradingTile(props: TradingTileProps): JSX.Element {
  const { mode, value, className = '', style } = props;
  const { t } = useTranslation();
  const classes = useStyles({ mode });

  const displayValue = !isNullOrUndefined(value) ? formatterWholeNumber.format(value) : '-';

  if (isTradingTileCreatedDateProps(props)) {
    return (
      <div
        className={clsx({
          [classes.tile]: true,
          [classes.spaceEvenly]: !isNull(value),
          [classes.justifyCenter]: isNull(value),
          [className]: true,
        })}
      >
        <Typography variant="subtitle2">{displayValue}</Typography>
        <Typography variant="body2" aria-label="created-date">
          {props.createdDate}
        </Typography>
      </div>
    );
  }

  if (isTradingTileUnitSizeProps(props))
    return (
      <div
        className={clsx({
          [classes.tile]: true,
          [classes.spaceEvenly]: !isNull(value),
          [classes.justifyCenter]: isNull(value),
          [className]: true,
        })}
      >
        <Typography variant="subtitle2">{displayValue}</Typography>
        {!isUndefined(props.unitCount) && !isUndefined(props.unitSize) && (
          <Typography variant="body2" aria-label="unit-count-and-size">
            ({props.unitCount} x {props.unitSize})
          </Typography>
        )}
      </div>
    );

  if (props.onClick && mode === 'bid' && isNullOrUndefined(value)) {
    return (
      <ButtonCard mode={mode} onClick={props.onClick} type="button" style={style}>
        <Typography variant="subtitle2">
          <Typography variant="subtitle2">{t('trade:makeBid')}</Typography>
        </Typography>
      </ButtonCard>
    );
  }

  if (props.onClick && !isNullOrUndefined(value)) {
    return (
      <ButtonCard mode={mode} type="button" onClick={props.onClick} style={style}>
        <Typography variant="subtitle2">
          <Typography variant="subtitle2">{displayValue}</Typography>
        </Typography>
      </ButtonCard>
    );
  }

  return (
    <div
      className={clsx({
        [classes.tile]: true,
        [classes.spaceEvenly]: !isNull(value),
        [classes.justifyCenter]: isNull(value),
        [className]: true,
      })}
    >
      <Typography variant="subtitle2">{displayValue}</Typography>
    </div>
  );
}
