import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DotGauge from '../DotGauge';
import Typography from '../Typography';
import CalculationToolTip from '../CalculationToolTip';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(5),
  },
  liquidity: {
    marginTop: theme.spacing(1.5),
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  oneline: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-end',
  },
}));
interface Props {
  criticScore: number | null | undefined;
  liquidtyScore: number | null;
  className?: string;
}

export default function Scores({ criticScore, liquidtyScore, className = '' }: Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${className}`}>
      <div>
        <Typography className={classes.uppercase} customVariant="label1" zellarColour="textLabel">
          {t('search:labels.liquidityScore')}
          <CalculationToolTip title={t('portfolio:liquidityScoreCalculation')} />
        </Typography>
        <div className={classes.liquidity}>
          {!isNull(liquidtyScore) ? <DotGauge dotCount={5} filledDots={liquidtyScore} /> : t('product:notAvailable')}
        </div>
      </div>
      <div>
        <Typography className={classes.uppercase} customVariant="label1" zellarColour="textLabel">
          {t('product:criticScore')}
        </Typography>
        <div className={classes.oneline}>
          <Typography customVariant="h5">{criticScore || t('product:notAvailable')}</Typography>
          <Typography customVariant="subtitle2" zellarColour="textLabel">
            /100
          </Typography>
        </div>
      </div>
    </div>
  );
}
