import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WineConfirmSuccess from '../../assets/images/wine-confirm-success.png';
import useMediaQuery from '../../hooks/useMediaQuery';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(7.5),
    paddingRight: theme.spacing(7.5),
  },
  iconContainer: {
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  label: {
    marginBottom: theme.spacing(8),
    textAlign: 'center',
  },
  button: {
    minWidth: 100,
    alignSelf: 'center',
    marginBottom: theme.spacing(3),
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function CardPaymentSuccessModal({ open, onClose }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return (
    <Dialog open={open} fullScreen={lessThanMd}>
      <div className={classes.modalContainer}>
        <DialogTitle id="dialog-title" onClose={onClose}>
          {' '}
        </DialogTitle>
        <div className={classes.iconContainer}>
          <img src={WineConfirmSuccess} height="212" alt="success" />
        </div>
        <Typography className={classes.title} variant="h2">
          {t('wallet:payByCard.success.hint')}
        </Typography>
        <Typography className={classes.label} variant="body1">
          <div>{t('wallet:payByCard.success.description1')}</div>
          <div>{t('wallet:payByCard.success.description2')}</div>
        </Typography>
        <Button className={classes.button} variant="contained" color="primary" size="large" onClick={onClose}>
          {t('wallet:payByCard.success.button')}
        </Button>
      </div>
    </Dialog>
  );
}
