import { sort } from '@cultwines/zellar-client-sdk/utils/sort';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import WatchlistAssets from '../../components/WatchlistAssets';
import useTableControls from '../../hooks/useTableControls';
import { useWatchlistItems } from '../../hooks/useWatchlistItems';
import { getPage } from '../../utils/getPage';
import { selectError, selectWatchlistItems, WatchlistFacets } from './selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gap: theme.spacing(4),
    },
  },
  searchResults: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      minWidth: '75%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const PAGE_SIZE_VARIANTS = [20, 60, 90];

export default function Watchlist(): JSX.Element {
  const classes = useStyles();
  const { loading, error, watchlistItems: watchlistItemsRaw } = useWatchlistItems();
  const { handleChangePage, handleChangeResultsPerPage, handleSortUpdated, page, pageSize, sortDirection, sortFacet } =
    useTableControls<WatchlistFacets>({
      defaultFacet: 'name',
      pageSizeVariants: PAGE_SIZE_VARIANTS,
    });

  const assets = React.useMemo(
    () =>
      selectWatchlistItems(watchlistItemsRaw)
        .sort((a, b) => sort(a[sortFacet] ?? null, b[sortFacet] ?? null, sortDirection))
        .slice(...getPage(page, pageSize)),
    [watchlistItemsRaw, sortFacet, sortDirection, page, pageSize],
  );

  return (
    <div className={classes.container}>
      <WatchlistAssets
        className={classes.searchResults}
        results={assets}
        resultsCount={watchlistItemsRaw.length}
        loading={loading}
        error={selectError(error)}
        page={page}
        pageSizeVariants={PAGE_SIZE_VARIANTS}
        rowsPerPage={pageSize}
        onChangePage={handleChangePage}
        onRowsPerPageChanged={handleChangeResultsPerPage}
        activeSortKey={sortFacet}
        onSortChanged={handleSortUpdated}
        sortDirection={sortDirection}
      />
    </div>
  );
}
