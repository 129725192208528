import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import OopsImage from '../../assets/images/404-robot.png';
import Typography from '../../components/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(2),
    textAlign: 'center',
  },
}));

export default function NotFound(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography customVariant="h1">{t('common:pageNotFoundTitle')}</Typography>
      <Typography customVariant="subtitle1">{t('common:pageNotFoundMessage')}</Typography>
      <div>
        <img src={OopsImage} alt={t('common:pageNotFoundTitle')} />
      </div>
    </div>
  );
}
