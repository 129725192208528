import { gql, useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import CircleCheckOutlineIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { isNull } from '../../utils/isNull';
import { logError } from '../../utils/logger';
import TradingCard from '../TradingCard';
import TradingCardSkeleton from '../TradingCard/Skeleton';
import Typography from '../Typography';
import { selectHighestBid, selectUserId } from './selectors';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.card.accent,
    stroke: theme.palette.card.accent,
    width: theme.spacing(4),
    height: theme.spacing(4),
    strokeWidth: '0.5px',
  },
}));

const GET_BID_FOR_ASSET_NO_AUTH = gql`
  query BidForAssetNoAuth($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      spread {
        highestBid {
          userId
          price
        }
      }
    }
  }
`;

export const GET_BID_FOR_ASSET_QUERY = gql`
  query BidForAssetWithAuth($assetId: Int!) {
    productAsset(assetId: $assetId) {
      id
      spread {
        highestBid {
          userId
          price
        }
      }
    }

    authUser {
      userId
    }
  }
`;

interface BidCardProps {
  assetId: number;
  className?: string;
  onViewClick?: () => void;
  showButton?: boolean;
  isLoggedIn?: boolean;
}

export default function BidCard({
  className = '',
  assetId,
  onViewClick,
  showButton = false,
  isLoggedIn = false,
}: BidCardProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    data,
    loading,
    error: failedToLoadBid,
  } = useQuery(isLoggedIn ? GET_BID_FOR_ASSET_QUERY : GET_BID_FOR_ASSET_NO_AUTH, {
    variables: { assetId },
    ...(isLoggedIn
      ? {}
      : {
          context: {
            serviceName: 'insecure',
          },
        }), // Conditionally add serviceName
  });

  function handleClick() {
    if (onViewClick) {
      onViewClick();
    }
  }

  const highestBid = useMemo(() => selectHighestBid(data?.productAsset), [data]);
  const currentUserId = useMemo(() => selectUserId(data?.authUser), [data]);

  if (loading) {
    return <TradingCardSkeleton className={className} />;
  }

  if (failedToLoadBid) {
    logError({ error: failedToLoadBid, filename: 'BidCard' });
    return <div>{failedToLoadBid.message}</div>;
  }

  const Title =
    !isNull(highestBid) && !isNull(currentUserId) && highestBid.userId === currentUserId ? (
      <Typography customVariant="subtitle1">
        {t('product:trading.highestBidderPersonal')} <CircleCheckOutlineIcon className={classes.icon} />
      </Typography>
    ) : (
      <Typography customVariant="subtitle1">{t('product:trading.highestBid')}</Typography>
    );

  return (
    <TradingCard
      className={className}
      title={Title}
      mode="bid"
      value={
        !isNull(highestBid) && !isNullOrUndefined(highestBid.price)
          ? formatterWholeNumber.format(highestBid.price)
          : '--'
      }
      showButton={showButton}
      actionText={t('product:trading.viewAllBids')}
      onActionClicked={handleClick}
    />
  );
}
