import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AvailableBalanceCard from '../AvailableBalanceCard';
import BidCard from '../BidCard';
import { DialogContent, DialogContentBorderBottomOnly } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import OfferCard from '../OfferCard';
import OrderBookModal from '../OrderBookModal';
import Typography from '../Typography';
import { Mode, TradeVariant } from './types';
import { useDisplayName } from './useDisplayName';
import OrderForm from '../OrderForm';
import { FormState, OrderData } from '../OrderForm/types';
import { useUnitSize } from '../../hooks';
import { logError } from '../../utils/logger';

const useStyles = makeStyles((theme) => ({
  modalContainer: { maxWidth: 800, paddingLeft: theme.spacing(7.5), paddingRight: theme.spacing(7.5) },
  title: {
    marginBottom: theme.spacing(2),
  },
  unitSizeContainer: {
    display: 'flex',
    marginBottom: theme.spacing(8),

    '& :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  infoCardsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
  },
  infoCards: { display: 'flex', gap: theme.spacing(3) },
  footer: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
  },
  formContainer: {
    display: 'flex',
  },
  tradingCard: {
    width: '160px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  balanceCard: {
    width: 'fit-content',
  },
}));

export type OrderDetailsPropsBase = {
  onClose: () => void;
  onSuccess: (state: OrderData) => void;
  onError: () => void;
  assetId: number;
  loading?: boolean;
  disableSubmit?: boolean;
  disableClose?: boolean;
  tradeType: TradeVariant;
  formState?: FormState;
  showFees?: boolean;
};

type EditOrderDetailsProps = OrderDetailsPropsBase & {
  mode: Mode.Edit;
  orderId: string;
};

type CreateOrderDetailsProps = OrderDetailsPropsBase & {
  mode: Mode.Create;
};

export default function OrderDetails(props: CreateOrderDetailsProps | EditOrderDetailsProps): JSX.Element {
  const {
    onClose,
    onSuccess,
    onError,
    assetId,
    loading,
    mode,
    tradeType,
    formState,
    disableSubmit = false,
    disableClose = false,
    showFees,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const { displayName, loading: productLoading, error: displayNameQueryError } = useDisplayName({ assetId });
  const { unitSize, error: unitSizeQueryError } = useUnitSize({ assetId });

  if (displayNameQueryError) {
    logError({
      error: new Error('Error when getting display name'),
      originalError: displayNameQueryError,
      filename: 'OrderModal/Details',
      additionalInfo: { assetId, mode, tradeType },
    });
  }

  if (unitSizeQueryError) {
    logError({
      error: new Error('Error when getting unit size'),
      originalError: unitSizeQueryError,
      filename: 'OrderModal/Details',
      additionalInfo: { assetId, mode, tradeType },
    });
  }

  return (
    <div className={classes.modalContainer}>
      <DialogTitle id="dialog-title" onClose={onClose} disableClose={disableClose}>
        {t(`product:detailsModal.${tradeType}.${mode}.title` as const)}
      </DialogTitle>
      <DialogContent dividers>
        {!productLoading ? (
          <Typography className={classes.title} variant="h2">
            {displayName}
          </Typography>
        ) : (
          <Typography variant="h2" className={classes.title}>
            <Skeleton aria-label="display-name-skeleton" />
          </Typography>
        )}
        <div className={classes.unitSizeContainer}>
          {unitSize ? (
            <>
              <Typography variant="subtitle1">{t('product:unitSize')}</Typography>
              <Typography variant="subtitle1">{unitSize.label}</Typography>
            </>
          ) : (
            <Skeleton variant="rectangular" width="100px" height="30px" />
          )}
        </div>
        <div className={classes.infoCardsContainer}>
          <div className={classes.infoCards}>
            <BidCard
              assetId={assetId}
              className={classes.tradingCard}
              onViewClick={() => setOpenModal(true)}
              showButton
            />
            <OfferCard
              assetId={assetId}
              className={classes.tradingCard}
              onViewClick={() => setOpenModal(true)}
              showButton
            />
          </div>
          <AvailableBalanceCard className={classes.balanceCard} showButton />
        </div>
      </DialogContent>
      <DialogContentBorderBottomOnly dividers>
        <Typography className={classes.title} variant="h2">
          {t(`product:detailsModal.${tradeType}.subtitle` as const)}
        </Typography>
        <div className={classes.formContainer}>
          {mode === 'create' ? (
            <OrderForm
              mode={mode}
              onError={onError}
              onSubmit={onSuccess}
              assetId={assetId}
              loading={loading || productLoading}
              disableSubmit={disableSubmit}
              tradeType={tradeType}
              formState={formState}
              showFees={showFees}
            />
          ) : (
            <OrderForm
              mode={mode}
              onError={onError}
              onSubmit={onSuccess}
              orderId={(props as EditOrderDetailsProps).orderId}
              assetId={assetId}
              loading={loading || productLoading}
              disableSubmit={disableSubmit}
              tradeType={tradeType}
              formState={formState}
              showFees={showFees}
            />
          )}
        </div>
      </DialogContentBorderBottomOnly>
      {openModal && <OrderBookModal assetId={assetId} open={openModal} onClose={() => setOpenModal(false)} />}
    </div>
  );
}
