import Link from '@mui/material/Link';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as TradeArrows } from '../../assets/icons/trade-arrows.svg';
import LogoCondensedPNG from '../../assets/images/logo-condensed.png';
import { ReactComponent as WatchlistEye } from '../../assets/icons/watchlist-eye.svg';
import { ReactComponent as WineBottles } from '../../assets/icons/wine-bottles.svg';
import { ReactComponent as DiscoverEye } from '../../assets/icons/discover-eye.svg';
import Typography from '../Typography';
import { NavigationPathEnum } from '../../types';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'none',

    '@media (min-width: 768px) and (min-height: 750px)': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      width: '84px',
      backgroundColor: theme.palette.card.background.dark,
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    textAlign: 'center',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    width: '100%',
    minWidth: 'fit-content',
    color: theme.palette.navigation.background.default.contrastText,
    fill: theme.palette.navigation.background.default.contrastText,
    stroke: theme.palette.navigation.background.default.contrastText,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  active: {
    width: '100%',
    minWidth: 'fit-content',
    background: theme.palette.navigation.background.active.main,
    color: theme.palette.navigation.background.active.contrastText,
    fill: theme.palette.navigation.background.active.contrastText,
    stroke: theme.palette.navigation.background.active.contrastText,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  logo: {
    display: 'block',
  },
  icon: {
    width: '2rem',
    height: '2rem',
    strokeWidth: '2.25px', // otherwise the stroke looks too thin when the link is active
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  versionNumber: {
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: '4px',
    color: theme.palette.navigation.background.default.contrastText,
    overflowWrap: 'break-word',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}));

interface NavigationBarProps {
  className?: string;
}

export default function NavigationBar({ className = '' }: NavigationBarProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { displayInternalVersionNumber } = useFlags();
  return (
    <nav className={clsx(classes.container, className)}>
      <div className={classes.section}>
        <Link component={RouterLink} to="/" className={classes.link}>
          <img width="52" src={LogoCondensedPNG} alt="cult-x" />
          <Typography customVariant="navigation">{t('common:beta')}</Typography>
        </Link>
      </div>
      <div className={classes.section}>
        <Link component={RouterLink} to="/discover" className={classes.link} activeClassName={classes.active}>
          <DiscoverEye className={classes.icon} />
          <Typography customVariant="navigation">{t('common:discover')}</Typography>
        </Link>
        <Link
          component={RouterLink}
          to={NavigationPathEnum.WATCHLIST}
          className={classes.link}
          activeClassName={classes.active}
        >
          <WatchlistEye className={classes.icon} />
          <Typography customVariant="navigation">{t('common:watchlist')}</Typography>
        </Link>
        <Link component={RouterLink} to="/portfolio" className={classes.link} activeClassName={classes.active}>
          <WineBottles className={classes.icon} />
          <Typography customVariant="navigation">{t('common:portfolio')}</Typography>
        </Link>
        <Link component={RouterLink} to="/trade" className={classes.link} activeClassName={classes.active}>
          <TradeArrows className={classes.icon} />
          <Typography customVariant="navigation">{t('common:myTrade')}</Typography>
        </Link>
      </div>
      <div className={classes.bottomSection}>
        <a className={classes.link} href="https://knowledge.cultx.com" target="_blank" rel="noreferrer">
          <Typography customVariant="navigation">FAQs</Typography>
        </a>
        {/* Intentionally commented out as we do not have a contact us section yet */}
        {/* <Link className={classes.link} component={RouterLink} to="/contact">
          <Typography customVariant="navigation">{t('common:contact')}</Typography>
        </Link> */}
      </div>
      {displayInternalVersionNumber &&
        process.env.REACT_APP_VERSION_NUMBER_INTERNAL &&
        process.env.REACT_APP_VERSION_NUMBER_INTERNAL.length > 0 && (
          <Typography customVariant="navigation" className={classes.versionNumber}>
            {process.env.REACT_APP_VERSION_NUMBER_INTERNAL}
          </Typography>
        )}
    </nav>
  );
}
