import { isUndefined, SelectOption } from '@cultwines/zellar-client-sdk';
import { SelectChangeEvent, Theme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import SelectSkeleton from './Skeleton';
import { useStyles } from './styles';

const BootstrapSelect = withStyles((theme) => ({
  select: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '&:hover': {
      background: theme.palette.grey[50],
    },
  },
  icon: {
    color: theme.palette.textPrimary,
    marginRight: theme.spacing(3),
  },
}))(Select);

interface SelectProps {
  optionId?: number;
  setOption: (option: number) => void;
  options: SelectOption[];
  inputLabel?: string;
  loading?: boolean;

  /**
   * Determines whether the label is displayed inline with the
   * select options. If false, label is displayed above the select.
   * We have a slight variation in the select elements defined in the
   * mockups, so this helps to cater for both variants.
   */
  inlineLabel?: boolean;
  dataTestId?: string;
  /**
   * Allows you to set styles on the BaseInput, which takes precedent
   * over any internal styles. This is useful where you want to set
   * specific width for the select based on screen size or content.
   */
  inputClass?: string;
  containerClassName?: string;
  placeholder?: string;
  disableSelect?: boolean;
  error?: boolean;
}

const usePlaceholderStyles = makeStyles<Theme, { disabled: boolean }>((theme) => ({
  placeholder: {
    color: ({ disabled }) => (disabled ? theme.palette.text.disabled : theme.palette.text.primary),
  },
}));

const Placeholder = ({ children, disabled }: { children: string; disabled: boolean }) => {
  const classes = usePlaceholderStyles({ disabled });
  return <div className={classes.placeholder}>{children}</div>;
};

export default function CustomizedSelects({
  options,
  inputLabel = '',
  dataTestId = '',
  // Prevents mui error that says this component
  // is chainging from uncontrolled to controlled
  optionId = -1,
  setOption,
  inlineLabel = false,
  inputClass = '',
  containerClassName = '',
  placeholder = '',
  loading = false,
  disableSelect = false,
  error = false,
}: SelectProps): JSX.Element {
  const disabled = !options.length;
  const classes = useStyles({ disabled });
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setOption(event.target.value as number);
  };
  if (loading) {
    return <SelectSkeleton />;
  }
  return (
    <FormControl className={`${classes.container} ${containerClassName}`}>
      <BootstrapSelect
        data-testid={dataTestId}
        error={error}
        disabled={disableSelect || disabled}
        value={optionId}
        onChange={handleChange}
        MenuProps={{
          style: {
            maxHeight: 300,
          },
        }}
        renderValue={
          isUndefined(optionId) || optionId === -1
            ? () => <Placeholder disabled={disabled}>{placeholder}</Placeholder>
            : undefined
        }
        input={
          <InputBase
            classes={{
              formControl: classes.inputBaseFormControl,
              input: `${classes.inputBaseInput} ${inputClass ?? inputClass}`,
            }}
          />
        }
      >
        {options.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            classes={{ root: classes.menuItemRoot, selected: classes.muiItemSelected }}
          >
            {inlineLabel && `${inputLabel}:`} {label}
          </MenuItem>
        ))}
      </BootstrapSelect>
    </FormControl>
  );
}
