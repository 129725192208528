import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Splash from '../../components/Splash';
import useMediaQuery from '../../hooks/useMediaQuery';
import SellingPoint from './SellingPoint';
import { useStyles } from './styles';
import cultXLlogoV2 from '../../assets/images/cultX_logo_v2.png';
import AuthTabs from '../AuthV2';
import { UrlPath } from '../../types/Enums';
// import { ReactComponent as CultXLogo } from '../../assets/icons/cultx_logo.svg';
// import CultXLogoMemo from '../../components/CultXLogo';
interface LoginV2Props {
  tab?: UrlPath;
}

export default function LoginV2({ tab = UrlPath.LOGIN }: LoginV2Props): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userHasRegistered, setUserHasRegistered] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(UrlPath.LOGIN);
  const onTabChange = (selectedTab: UrlPath) => {
    setCurrentTab(selectedTab);
  };

  return (
    <Splash className={classes.splashContainer}>
      <div className={classes.container}>
        {userHasRegistered ? (
          <Typography variant="h2" textAlign="center">
            {t('account:register.success')}
          </Typography>
        ) : (
          <>
            {greaterThanSm && (
              <div className={`${classes.marketing} ${classes.column}`}>
                {/* <div>
                  <CultXLogoMemo />
                </div> */}

                <div className={classes.header}>
                  <div style={{ width: 200, height: '68.19px' }}>
                    <img src={cultXLlogoV2} alt="logo" />
                  </div>
                  <Typography classes={classes.headingTextV2} variant="h1">
                    {t('account:auth_v2.heading')}
                  </Typography>
                  <Typography classes={classes.subHeadingTextV2} variant="h4">
                    {currentTab === UrlPath.LOGIN
                      ? t('account:auth_v2.signInSubHeading')
                      : t('account:auth_v2.signUpSubHeading')}
                  </Typography>
                </div>
                <div className={classes.marketingWidgets}>
                  <SellingPoint
                    title={t('account:register.sellingPoint1.title')}
                    text={t('account:register.sellingPoint1.text')}
                  />
                  <SellingPoint
                    title={t('account:register.sellingPoint2.title')}
                    text={t('account:register.sellingPoint2.text')}
                  />
                  <SellingPoint
                    title={t('account:register.sellingPoint3.title')}
                    text={t('account:register.sellingPoint3.text')}
                  />
                </div>
                <Typography variant="subtitle2" color="textPrimary" className={classes.footerText}>
                  {t('account:register.footer1')}
                </Typography>
                <Typography variant="subtitle2" color="textPrimary" className={classes.footerText}>
                  {t('account:register.footer2')}
                </Typography>
                <Typography variant="subtitle2" color="textPrimary" className={classes.footerText}>
                  {t('account:register.footer3')}
                </Typography>
                <div>
                  <Typography variant="subtitle2" color="textPrimary" className={classes.copyright}>
                    {t('common:copyright.info').toUpperCase()}
                  </Typography>
                  <Typography variant="subtitle2" color="textPrimary" className={classes.copyright}>
                    {t('common:copyright.address').toUpperCase()}
                  </Typography>
                </div>
              </div>
            )}
            <AuthTabs tab={tab} onTabChange={onTabChange} />
          </>
        )}
      </div>
    </Splash>
  );
}
