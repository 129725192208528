import React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import DialogTitle from '../Dialog/DialogTitle';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import PaymentCard from '../PaymentCard';
import Typography from '../Typography';
import Switch from '../Switch';
import { useStyles } from './styles';
import { cache } from '../../graphql/cache';
import { logError } from '../../utils/logger';
import { UPDATE_MIT_CONSENT } from '../../graphql/mutations/updateMitConsent';
import { GET_STORED_CARD_DETAILS } from '../../graphql/queries/storedCardDetails';
import { UPDATE_DEFAULT_MIT_CARD } from '../../graphql/mutations/updateDefaultMitCard';
import useUserDetails from '../../hooks/useUserDetails';
import { AccountType } from '../../types/AccountType';
import { CardDetailResponse } from '../../__generated__/graphql';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

interface ManageCardFormProps {
  selectedCard: CardDetailResponse;
  onClose: () => void;
  onResetDefault: () => void;
  onFailure: () => void;
  onDeleteMit: () => void;
  onDeleteCard: () => void;
}

export default function ManageCardForm({
  selectedCard,
  onClose,
  onResetDefault,
  onFailure,
  onDeleteMit,
  onDeleteCard,
}: ManageCardFormProps): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();

  const previousMitState = Boolean(selectedCard?.mitConsentedFlag);
  const previousDefaultState = Boolean(selectedCard?.defaultMitFlag);
  const [mitEnabled, setMitEnabled] = React.useState(previousMitState);
  const [defaultCardEnabled, setDefaultCardEnabled] = React.useState(previousDefaultState);
  const [updateMitConsent, { loading: processingMitConsentRequest }] = useMutation(UPDATE_MIT_CONSENT, {
    refetchQueries: [{ query: GET_STORED_CARD_DETAILS }],
    onError: () => null,
  });
  const [updateDefaultCard, { loading: processingDefaultCardRequest }] = useMutation(UPDATE_DEFAULT_MIT_CARD, {
    refetchQueries: [{ query: GET_STORED_CARD_DETAILS }],
    onError: () => null,
  });
  const { userDetails } = useUserDetails();
  const isWhaleAccount = userDetails?.accountType === AccountType.Whale;

  function handleMitEnableChange(e: React.ChangeEvent<HTMLInputElement>) {
    setMitEnabled(e.target.checked);
    if (e.target.checked) {
      setDefaultCardEnabled(previousDefaultState);
    } else {
      setDefaultCardEnabled(false);
    }
  }

  function handleDefaultCardChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDefaultCardEnabled(e.target.checked);
  }

  async function onSubmit() {
    try {
      if (previousMitState === false && mitEnabled === true) {
        if (!isNullOrUndefined(selectedCard.instrumentId)) {
          const { data: updateMitConsentResponse, errors: updateMitConsentError } = await updateMitConsent({
            variables: {
              updateMitConsentInput: {
                consentFlag: mitEnabled,
                instrumentId: selectedCard.instrumentId,
              },
            },
          });
          if (updateMitConsentResponse?.updateMitConsent.errorMessage) {
            throw new Error(updateMitConsentResponse?.updateMitConsent.errorMessage);
          }
          if (updateMitConsentError) {
            throw updateMitConsentError[0];
          }
          cache.evict({ fieldName: 'paymentGetCardDetails' });
          cache.evict({ fieldName: 'omsGetMyOrders' });
          cache.evict({ fieldName: 'authUser' });
        } else {
          onFailure();
        }
      } else if (previousMitState === true && mitEnabled === false) {
        onDeleteMit();
        return;
      }

      if (previousDefaultState === false && defaultCardEnabled === true) {
        if (!isNullOrUndefined(selectedCard.instrumentId)) {
          const { data: updateDefaultCardResponse, errors: updateDefaultCardError } = await updateDefaultCard({
            variables: {
              defaultMitCard: {
                instrumentId: selectedCard.instrumentId,
              },
            },
          });
          if (updateDefaultCardResponse?.updateDefaultMitCard.errorMessage) {
            throw new Error(updateDefaultCardResponse?.updateDefaultMitCard.errorMessage);
          }
          if (updateDefaultCardError) {
            throw updateDefaultCardError[0];
          }
          onClose();
        } else {
          onFailure();
        }
      } else if (previousDefaultState === true && defaultCardEnabled === false) {
        setDefaultCardEnabled(true);
        onResetDefault();
      } else {
        onClose();
      }
    } catch (error) {
      logError({
        originalError: error as Error,
        error: new Error('Failed to manage card'),
        filename: 'ManageCardForm',
        tags: { userFlow: 'payment' },
        additionalInfo: {
          errors: JSON.stringify(error),
        },
      });
      onFailure();
    }
  }

  return (
    <>
      <DialogTitle id="manage-card-title" onClose={onClose}>
        <div className={classes.textCapitalize}>{t('wallet:manageCard')}</div>
      </DialogTitle>
      <DialogContentBorderTopOnly dividers>
        <div className={classes.container}>
          <PaymentCard className={classes.cardItem} data={selectedCard} />

          <div className={classes.row}>
            <Typography variant="subtitle1" className={classes.textCapitalize}>
              {t('wallet:payByCard.mitEnabled')}
            </Typography>
            <Switch
              checked={mitEnabled}
              disabled={isWhaleAccount}
              onChange={handleMitEnableChange}
              inputProps={{ 'aria-label': 'mit-switch' }}
            />
          </div>
          <div className={classes.row}>
            <Typography
              variant="subtitle1"
              className={`${classes.textCapitalize} ${!mitEnabled ? classes.textDisabled : ''}`}
            >
              {t('wallet:defaultCard')}
            </Typography>
            <Switch
              checked={defaultCardEnabled}
              onChange={handleDefaultCardChange}
              inputProps={{ 'aria-label': 'default-card-switch' }}
              disabled={!mitEnabled}
            />
          </div>

          <div className={classes.row}>
            <Button
              className={classes.textCapitalize}
              variant="contained"
              color="primary"
              size="medium"
              onClick={onDeleteCard}
            >
              {t('wallet:deleteCard')}
            </Button>
            <Button
              className={classes.textCapitalize}
              variant="contained"
              color="primary"
              size="medium"
              disabled={processingMitConsentRequest || processingDefaultCardRequest}
              onClick={onSubmit}
            >
              {t('common:done')}
            </Button>
          </div>
        </div>
      </DialogContentBorderTopOnly>
    </>
  );
}
