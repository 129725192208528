import { useQuery, useReactiveVar } from '@apollo/client';
import { SelectOption } from '@cultwines/zellar-client-sdk';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { GraphQLError } from 'graphql';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RRLink, useHistory, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import WineStubImage from '../../assets/images/stub-wine-image.png';
import { EXTERNAL_PORTFOLIO_USER_HAS_ASSET } from '../../graphql/queries/externalPortfolioUserHasAsset';
import { PORTFOLIO_USER_HAS_ASSET } from '../../graphql/queries/portfolioUserHasAsset';
import { selectErrorMessage } from '../../graphql/selectors/selectErrorMessage';
import { useCalculatedMarketData } from '../../hooks/useCalculatedMarketData';
import useDoesAssetExistInAnyHoldings from '../../hooks/useDoesAssetExistInAnyHoldings';
import useMediaQuery from '../../hooks/useMediaQuery';
import { colours } from '../../theme/light/palette';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { massageToNull } from '../../utils/massageToNull';
import { PortfolioTabs } from '../../views/Portfolio';
import { selectLiquidityScore } from '../../views/Search/selectors';
import ErrorPlaceholder from '../ErrorPlaceholder';
import UnitSizeSelect from '../Select/UnitSizeSelect';
import VintageSelectUncontrolled from '../Select/VintageSelectUncontrolled';
import TransferAssetToExternalPortfolioModal from '../TransferAssetToExternalPortfolioModal/PreselectedAsset';
import Typography from '../Typography';
import WatchlistButton from '../WatchlistButton';
import Badge from './Badge';
import MarketPricePercentageChange from './MarketPricePercentageChange';
import OptionsMenu from './OptionsMenu';
import { PRODUCT_DATA_WATCHLIST_QUERY } from './queries';
import Scores from './Scores';
import { selectProductSummary, selectVintages } from './selectors';
import ProductSummarySkeleton from './Skeleton';
import useStyles from './styles';
import CalculationToolTip from '../CalculationToolTip';
import SocialShareButton from '../SocialShare/SocialShareButton';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { usePercentageDifference } from '../../hooks/usePercentageDifference';
import calculateDateRange from '../../utils/calculateDateRange';
import { isLoggedInVar } from '../../graphql/cache';
import { makeUrlSafe } from '../../utils/common';
import { useAppLayoutContext } from '../AppLayout/Context';
import { ActionEventType, UrlPath } from '../../types/Enums';
import SEO from '../SEO';
import { selectActiveUnitSize } from '../Select/selectors';
import { useUnitSizes } from '../../hooks/useUnitSizes';
import { UrlConFig } from './types';

interface Props {
  assetId: number;
  description: string;
}

enum ChangeTypeEnum {
  UNIT_SIZE = 'unit_size',
  VINTAGE = 'vintage',
}

const removeWhitespce = (str: string) => str.replace(/[ ,]/g, '').toLowerCase().trim();

export default function ProductSummary({ assetId, description }: Props): JSX.Element {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { enableSharePageFeature } = useFlags();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentVintageId, setCurrentVintageId] = useState<number | null>(null);
  const [urlConFig, setUrlConFig] = useState<UrlConFig>({ isInit: true, isError: false, isLoading: true });
  const [refetchingUnitSize, setRefetchingUnitSize] = useState(false);
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { state, pathname } = useLocation();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const {
    state: { appState },
    dispatch: appStateDispatcher,
  } = useAppLayoutContext();
  // loading: loadingUnitSizes
  const { data: unitSizes, error: unitSizesError } = useUnitSizes({ assetId });

  const { data: doesAssetExistInAnyHoldings } = useDoesAssetExistInAnyHoldings({ assetId });
  const gqlContext = isLoggedIn ? {} : { context: { serviceName: 'insecure' } };
  const { data: isInExternalPortfolio } = useQuery(EXTERNAL_PORTFOLIO_USER_HAS_ASSET, {
    variables: {
      assetId,
    },
    pollInterval: 4000,
    ...gqlContext,
  });
  const { data: isInPortfolio } = useQuery(PORTFOLIO_USER_HAS_ASSET, {
    variables: {
      assetId,
    },
    ...gqlContext,
  });
  const { data, loading, error, refetch } = useQuery(PRODUCT_DATA_WATCHLIST_QUERY, {
    variables: {
      assetId,
    },
    ...gqlContext,
  });

  async function handleReloadProductData(): Promise<void> {
    await refetch({ assetId });
  }

  const { percentageDifference } = usePercentageDifference(
    { assetId, dateRange: calculateDateRange('1Y') },
    isLoggedIn,
  );
  const calcDataResponse = useCalculatedMarketData({ assetId }, isLoggedIn);
  const liquidityScore = selectLiquidityScore(
    massageToNull(calcDataResponse.data?.calculatedMarketData?.liquidityScore),
  );

  const vintages = selectVintages(data?.productAsset, assetId);
  const selectedUnitSize = state?.unitSize ? removeWhitespce(`${state.unitSize}`) : null;

  const onUpdateUnitSize = useCallback(
    (options: SelectOption<string>[]) => {
      return options.find((size) => removeWhitespce(size.label) === selectedUnitSize);
    },
    [selectedUnitSize],
  );

  useEffect(() => {
    if (!loading && currentVintageId) {
      refetch({ assetId: currentVintageId }).then((response) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { vintage, vintages: _vintages } = response.data?.productAsset!;
        const assets = _vintages.find((x) => x.id === vintage.id)?.assets;
        const selectedAsset = assets?.find((x) => {
          return removeWhitespce(`${x.unitCount}x${x.unitSize}`) === selectedUnitSize;
        });
        setRefetchingUnitSize(false);
        const _currentUnitSize = appState.unitSizeLabel?.replaceAll(' ', '');
        const wineUrlSlug = `${makeUrlSafe(vintage.wine.name)}_${vintage.vintage}_${_currentUnitSize}`;
        const prodUrl = `${selectedAsset?.id ?? currentVintageId}_${wineUrlSlug}`.trim();
        history.push(`${UrlPath.PRODUCT_PAGE}/${prodUrl}`, {
          unitSize: state?.unitSize,
          changeType: ChangeTypeEnum.VINTAGE,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVintageId]);

  useEffect(() => {
    const regex = /^\/product\/(\d+)_.*$/; // Corrected regex
    if (regex.test(pathname) && appState.unitSizeLabel && appState.unitSizeValue) {
      const pathUnitSize = pathname.trim().split('_').slice(-1)[0];
      const us = appState.unitSizeLabel?.replaceAll(' ', '');
      if (pathUnitSize !== us) {
        const newPath = pathname.trim().replace(/_[^_]*$/, `_${us}`);
        history.replace(`${newPath}`, {
          unitSize: appState.unitSizeLabel,
          changeType: ChangeTypeEnum.UNIT_SIZE,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.unitSizeValue, appState.unitSizeLabel]);

  useEffect(() => {
    if (data?.productAsset && urlConFig.isInit && unitSizes) {
      const { vintage } = data.productAsset!;
      const activeUnitSize = selectActiveUnitSize(unitSizes?.productAsset);

      if (!pathname) {
        setUrlConFig({ isInit: false, isLoading: false, isError: false });
        return;
      }

      const currentWineUrlSlug = (pathname || '').replace('/product/', '');
      const expectedWineUrlSlug = `${assetId}_${makeUrlSafe(vintage.wine.name)}_${
        vintage.vintage
      }_${activeUnitSize?.label?.replaceAll(' ', '')}`;

      // console.log('expectedWineUrlSlug ', expectedWineUrlSlug);
      // console.log('currentProduct ', currentWineUrlSlug);
      // console.log('currentProduct === wineUrlSlug ', expectedWineUrlSlug === currentWineUrlSlug);

      if (/^\d+$/.test(currentWineUrlSlug)) {
        // if only asset id is in url
        history.push(`${UrlPath.PRODUCT_PAGE}/${expectedWineUrlSlug}`);
        setUrlConFig({ isInit: false, isLoading: false, isError: false });
        return;
      }

      const isError = expectedWineUrlSlug !== currentWineUrlSlug;
      setUrlConFig({ isInit: false, isLoading: false, isError });
      appStateDispatcher({
        type: ActionEventType.UPDATE_STATE,
        payload: {
          isProductError: isError,
        },
      });
    } else if (unitSizesError || error) {
      setUrlConFig({ isInit: false, isLoading: false, isError: true });
      appStateDispatcher({
        type: ActionEventType.UPDATE_STATE,
        payload: {
          isProductError: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.productAsset,
    appState.unitSizeLabel,
    urlConFig.isInit,
    pathname,
    unitSizes,
    unitSizesError,
    assetId,
    error,
  ]);

  if (loading || refetchingUnitSize || urlConFig.isLoading) {
    return <ProductSummarySkeleton />;
  }

  if (error || !data?.productAsset || urlConFig.isError) {
    return (
      <ErrorPlaceholder
        error={error ? error.message : t('product:failedToLoadSummaryData')}
        action={
          <Button
            className={classes.retryButton}
            variant="contained"
            onClick={handleReloadProductData}
            disabled={loading}
          >
            {t('common:retry')}
          </Button>
        }
      />
    );
  }
  const normalisedProductData = { ...selectProductSummary(data?.productAsset), ...data?.productAsset };

  function afterUnitSizeChange(option: SelectOption) {
    if (option.value) {
      const wineUrlSlug =
        normalisedProductData && normalisedProductData.wineName
          ? `_${makeUrlSafe(normalisedProductData?.wineName)}_${normalisedProductData.vintage?.vintage}`
          : '';

      const _currentUnitSize = option.label.replaceAll(' ', '');
      // const _currentUnitSize = text.replace(/\s+/g, '');
      const prodUrl = `${option.value}${wineUrlSlug}_${_currentUnitSize}`.trim();
      console.log('afterUnitSizeChange');
      history.replace(`${UrlPath.PRODUCT_PAGE}/${prodUrl}`, {
        unitSize: option.label,
        changeType: ChangeTypeEnum.UNIT_SIZE,
      });
    }
  }

  function handleAddToWatchlist(): void {
    enqueueSnackbar(t('product:watchList.addedMessage'), { variant: 'info' });
  }

  function handleRemoveFromWatchlist(): void {
    enqueueSnackbar(t('product:watchList.deletedMessage'), { variant: 'info' });
  }

  function handleWatchlistButtonError(errors: readonly GraphQLError[]): void {
    enqueueSnackbar(selectErrorMessage(errors), { variant: 'error' });
  }

  function handleTransferRequested(): void {
    setModalOpen(true);
  }

  function handleVintageChanged(optionId: number) {
    setCurrentVintageId(optionId);
    setRefetchingUnitSize(true);
  }

  const wineUrlSlug =
    normalisedProductData && normalisedProductData.wineName
      ? `_${makeUrlSafe(normalisedProductData?.wineName)}_${normalisedProductData.vintage?.vintage}`
      : '';
  const _currentUnitSize = appState.unitSizeLabel?.replaceAll(' ', '');
  const prodUrl = `${assetId}${wineUrlSlug}_${_currentUnitSize}`.trim();
  const shortWineName = normalisedProductData.wineName?.split(',')[0];
  const seoTitle = `${shortWineName} ${normalisedProductData.vintage?.vintage} (${_currentUnitSize}) | CultX`;
  const seoDesc = `"Explore ${normalisedProductData.vintage?.vintage} vintage of ${normalisedProductData.displayName} on CultX. Buy, bid, or make an offer today!"`;

  return (
    <div className={classes.summaryDetails}>
      <img
        src={normalisedProductData.imageUrl || WineStubImage}
        className={classes.image}
        data-cy="wine-image"
        alt={t('product:wineImageAltTag')}
      />
      <div className={classes.details}>
        <div className={classes.actions}>
          <div className={classes.badgesContainer}>
            {!!isInExternalPortfolio?.externalPortfolioUserHasAsset && (
              <Link
                component={RRLink}
                to={`/portfolio?tab=${PortfolioTabs.ExternalPortfolio}`}
                className={classes.link}
              >
                <Badge label={t('portfolio:externalPortfolio.partOfYourPortfolio')} />
              </Link>
            )}
            {!!isInPortfolio?.isUserHoldingHasAsset && (
              <Link component={RRLink} to={`/portfolio?tab=${PortfolioTabs.CultXPortfolio}`} className={classes.link}>
                <Badge colour={theme.palette.card.accent} label={t('portfolio:partOfYourPortfolio')} />
              </Link>
            )}
            {doesAssetExistInAnyHoldings?.isAssetExistInAnyHoldings && (
              <Badge colour={colours.cultYellow} label={t('product:communityHolding')} />
            )}
          </div>
          <div className={classes.rightSideActions}>
            <WatchlistButton
              assetId={assetId}
              onAdd={handleAddToWatchlist}
              onRemove={handleRemoveFromWatchlist}
              onError={handleWatchlistButtonError}
            />
            <OptionsMenu assetId={assetId} handleTransferRequested={handleTransferRequested} />
          </div>
        </div>
        <Typography variant="h1" className={classes.productTitle}>
          {normalisedProductData.displayName}
        </Typography>
        {greaterThanSm && (
          <Typography variant="body1" zellarColour="textLabel">
            {description}
          </Typography>
        )}
        <div className={classes.selects}>
          <UnitSizeSelect
            assetId={assetId}
            onUpdateUnitSize={selectedUnitSize ? onUpdateUnitSize : undefined}
            afterUnitSizeChange={afterUnitSizeChange}
          />
          {!loading && (
            <>
              <VintageSelectUncontrolled
                options={vintages}
                onChange={handleVintageChanged}
                selectedOptionId={assetId}
                loading={loading}
              />

              {enableSharePageFeature && (
                <SocialShareButton
                  productId={`${assetId}`}
                  productUrl={prodUrl}
                  title={`${normalisedProductData.displayName}`}
                />
              )}
            </>
          )}
        </div>
        <div className={classes.horizontalContainer}>
          <div className={classes.marketValueContainer}>
            <Typography className={classes.uppercase} customVariant="label1" zellarColour="textLabel">
              {t('product:marketValue')}
              <CalculationToolTip title={t('market:marketValueCalculation')} />
            </Typography>
            <div className={classes.marketValue}>
              <Typography customVariant="h3">
                {!isNullOrUndefined(normalisedProductData.marketValue)
                  ? formatterWholeNumber.format(normalisedProductData.marketValue)
                  : '--'}
              </Typography>
              <MarketPricePercentageChange value={percentageDifference || undefined} />
            </div>
          </div>
          <Scores
            criticScore={calcDataResponse?.data?.calculatedMarketData.combinedScore}
            liquidtyScore={liquidityScore}
          />
        </div>
      </div>
      {modalOpen && (
        <TransferAssetToExternalPortfolioModal assetId={assetId} open={modalOpen} onClose={() => setModalOpen(false)} />
      )}
      <SEO title={seoTitle} description={`${seoDesc}`} path={`${UrlPath.PRODUCT_PAGE}/${prodUrl}`} />
    </div>
  );
}
