import { useQuery } from '@apollo/client';
import { Stack, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHistoricalMarketValueChangeData from '../../hooks/useHistoricalMarketValueChangeData';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ChartType } from '../../types/ChartType';
import { DateRangeVariant } from '../../types/DateRangeVariant';
import calculateDateRange from '../../utils/calculateDateRange';
import normalizeChartDateData from '../../utils/normalizeChartDateData';
import normalizeMultipleChartDateDataWithId from '../../utils/normalizeMultipleChartDateDataWithId';
import selectChartExtremes from '../../utils/selectChartExtremes';
import selectChartExtremesFromMultipleSeries from '../../utils/selectChartExtremesFromMultipleSeries';
import ToggleButton from '../ToggleButton';
import DateRangePicker from './DateRangePicker';
import SeriesLineChart from './SeriesLineChart';
import { HistoricalMarketDataChange } from '../../__generated__/graphql';
import MarketPerformance from './MarketPerformance';
import { GET_MARKET_DATA_QUERY } from '../../graphql/queries/marketData';

const dateRangeVariants = ['6M', '1Y', '5Y', '10Y', 'YTD', 'MAX'] as DateRangeVariant[];
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  toggleButtons: {
    marginLeft: 'auto',
  },
  footer: {
    marginLeft: theme.spacing(9),
  },
}));

interface ProductLineChartProps {
  assetId: number;
  isLoggedIn?: boolean;
  isProductError?: boolean;
}

export default function ProductLineChart({
  assetId,
  isLoggedIn = true,
  isProductError,
}: ProductLineChartProps): JSX.Element {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeVariant>('MAX');
  const [chartType, setChartType] = useState<ChartType>(ChartType.Absolute);
  const [dateFrom, setDateFrom] = useState<string>(calculateDateRange(selectedDateRange).from);
  const [dateTo, setDateTo] = useState<string>(calculateDateRange(selectedDateRange).to);
  const classes = useStyles();
  const theme = useTheme();
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation();
  const { data, loading, error, previousData } = useQuery(GET_MARKET_DATA_QUERY, {
    variables: {
      assetId,
      dateFrom,
      dateTo,
      includeWineSearcher: true,
    },
    ...(isLoggedIn
      ? {}
      : {
          context: {
            serviceName: 'insecure',
          },
        }), // Conditionally add serviceName
  });
  const { data: percentageData } = useHistoricalMarketValueChangeData(
    {
      variables: {
        assetIds: [assetId],
        dateFrom,
        dateTo,
      },
    },
    isLoggedIn,
  );

  function handleDateRangeChanged(dateRangeVariant: DateRangeVariant) {
    setSelectedDateRange(dateRangeVariant);
    const range = calculateDateRange(dateRangeVariant);
    setDateFrom(range.from);
    setDateTo(range.to);
  }

  function handleToggle(toggleState: boolean): void {
    setChartType(toggleState ? ChartType.Rebase : ChartType.Absolute);
  }

  const latestPercentageData = percentageData?.historicalMarketValueChange as HistoricalMarketDataChange[];
  const normalisedPercentageData = normalizeMultipleChartDateDataWithId(latestPercentageData)
    .map((asset) => asset.data)
    .reduce((prev, curr) => [...prev, ...curr], []);
  const latestMarketData = data?.productAsset?.marketData || previousData?.productAsset?.marketData;
  const latestData = latestMarketData?.wineSearcher;

  const normalisedData = normalizeChartDateData(latestData);
  const [percentageMin, percentageMax] = useMemo(
    () => selectChartExtremesFromMultipleSeries(latestPercentageData),
    [latestPercentageData],
  );
  const [min, max] = useMemo(() => selectChartExtremes(latestData), [latestData]);

  if (error || isProductError) {
    return <Typography>{t('common:error')}</Typography>;
  }
  return (
    <div className={classes.container}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <MarketPerformance
          performance={latestMarketData?.performance}
          dateRange={selectedDateRange}
          loading={loading}
        />
        <ToggleButton
          falseStateName={t('market:absolute')}
          trueStateName={t('market:rebase')}
          onToggle={handleToggle}
          toggleState={!!chartType}
        />
      </Stack>
      <SeriesLineChart
        data={chartType === ChartType.Absolute ? normalisedData : normalisedPercentageData}
        title={chartType === ChartType.Absolute ? t('product:price') : t('common:percentage')}
        min={chartType === ChartType.Absolute ? min : percentageMin}
        max={chartType === ChartType.Absolute ? max : percentageMax}
        loading={loading}
        chartType={chartType}
      />
      {greaterThanSm && (
        <div className={classes.footer}>
          <DateRangePicker
            dateRangeUpdated={handleDateRangeChanged}
            selectedDateRangeVariant={selectedDateRange}
            variants={dateRangeVariants}
            assetId={assetId}
          />
        </div>
      )}
    </div>
  );
}
