/* eslint-disable react/no-array-index-key */
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SelectedRowsProvider, useSelectedRowsContext } from '../../context/selectedRowsContext';
import useMediaQuery from '../../hooks/useMediaQuery';
import { uuid } from '../../utils/uuid';
import { WatchlistAsset, WatchlistFacets } from '../../views/WatchList/selectors';
import OrderModal from '../OrderModal';
import DeleteWatchListItemModal from '../DeleteWatchListItemModal';
import EmptyPlaceholder from '../EmptyPlaceholder';
import Table from '../Table';
import Toolbar, { ViewType } from '../Table/Toolbar';
import { Headers, SortDirection } from '../Table/types';
import TablePagination from '../TablePagination';
import WatchlistAssetCard from '../WineCard/WatchlistAssetCard';
import WineCardSkeleton from '../WineCardSkeleton';
import { getSortOptions, selectWatchlistTableData, TableData } from './selectors';
import ErrorPlaceholder from '../ErrorPlaceholder';
import { useQueryParameters } from '../../hooks';
import { Modal } from '../../types/Modal';
import BuyNowModal from '../BuyNowModal';
import useCardPaymentCallback from '../../hooks/useCardPaymentCallback';
import CardPaymentErrorModal from '../CardPaymentCallbackModal/errorContent';
import CardPaymentSuccessModal from '../CardPaymentCallbackModal/successContent';
import { Mode } from '../OrderModal/types';
import CalculationToolTip from '../CalculationToolTip';
import WatchlistTable from './Table';
import { TableRefactorFlagSet } from '../../types/FeatureFlags';

const useStyles = makeStyles((theme) => ({
  cardList: {
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
    padding: '0 10px',
  },
  card: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: `calc(50% - ${theme.spacing(2)})`,
    },

    [theme.breakpoints.up('xl')]: {
      width: `calc(33% - ${theme.spacing(2.2)})`,
    },
  },
}));

interface WatchlistAssetsProps {
  className?: string;
  results: WatchlistAsset[];

  /**
   * The total number of results, across all pages of data.
   */
  resultsCount: number;

  loading: boolean;
  error: null | string;
  rowsPerPage: number;
  page: number;
  pageSizeVariants: number[];
  onRowsPerPageChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePage: (event: unknown, newPage: number) => void;
  activeSortKey: keyof WatchlistFacets;
  sortDirection: SortDirection;
  onSortChanged: (key: keyof WatchlistFacets, direction?: SortDirection) => void;
}

function WatchlistAssets({
  loading,
  error,
  activeSortKey,
  onChangePage,
  onRowsPerPageChanged,
  onSortChanged,
  page,
  pageSizeVariants,
  results,
  resultsCount,
  rowsPerPage,
  sortDirection,
  className,
}: WatchlistAssetsProps): JSX.Element {
  const { clickableTradeTiles13816 } = useFlags();
  const theme = useTheme();
  const classes = useStyles();
  const { tableRefactor } = useFlags<{ tableRefactor?: TableRefactorFlagSet }>();
  const { state: selectedRowsState } = useSelectedRowsContext();
  const lessThanLg = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));
  const [viewType, setViewType] = useState<ViewType>('table');
  const [assetIdSelectedForDeletion, setAssetIdSelectedForDeletion] = useState<number | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { showCarPaymentErrorModal, showCarPaymentSuccessModal, showTopUpBuySuccessSummaryModal } =
    useCardPaymentCallback();
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = useQueryParameters();
  const modal = queryParams.get('modal');
  const assetId = queryParams.get('assetId');

  function handleOpenBuyNowModalClicked(_assetId: number): void;
  function handleOpenBuyNowModalClicked(rowId: string): void;
  function handleOpenBuyNowModalClicked(id: string | number): void {
    history.push(`${history.location.pathname}?modal=${Modal.BuyNow}&assetId=${id}`);
  }

  function handleOpenBidModalClicked(_assetId: number): void;
  function handleOpenBidModalClicked(rowId: string): void;
  function handleOpenBidModalClicked(id: string | number): void {
    history.push(`${history.location.pathname}?modal=${Modal.Bid}&assetId=${id}`);
  }

  function handleOpenOfferModalClicked(_assetId: number): void;
  function handleOpenOfferModalClicked(rowId: string): void;
  function handleOpenOfferModalClicked(id: string | number): void {
    history.push(`${history.location.pathname}?modal=${Modal.Offer}&assetId=${id}`);
  }

  function handleCloseModal() {
    queryParams.delete('modal');
    queryParams.delete('assetId');
    history.push({ pathname: history.location.pathname, search: queryParams.toString(), hash: history.location.hash });
  }

  const common = {
    onClick: onSortChanged,
    direction: sortDirection,
  };
  const headers: Headers<Omit<TableData, 'rowId'>, keyof WatchlistFacets> = {
    wineName: { title: t('market:table.wineName'), id: 'name', active: activeSortKey === 'name', ...common },
    vintage: { title: t('common:vintage'), id: 'vintage', active: activeSortKey === 'vintage', ...common },
    unitSize: { title: t('common:unitSize'), id: 'unitCount', active: activeSortKey === 'unitCount', ...common },
    score: { title: t('product:score'), id: 'score', active: activeSortKey === 'score', ...common },
    marketValue: {
      title: t('product:marketValue'),
      id: 'marketValue',
      active: activeSortKey === 'marketValue',
      extraElement: <CalculationToolTip title={t('market:marketValueCalculation')} />,
      ...common,
    },
    performance: {
      title: t('market:performanceLTM'),
      id: 'performancePercentageDelta',
      active: activeSortKey === 'performancePercentageDelta',
      ...common,
    },
    highestBid: {
      title: t('product:trading.highestBid'),
      id: 'highestBid',
      active: activeSortKey === 'highestBid',
      ...common,
    },
    lowestOffer: {
      title: t('product:trading.lowestOffer'),
      id: 'lowestOffer',
      active: activeSortKey === 'lowestOffer',
      ...common,
    },
  };

  const sortOptions = React.useMemo(
    () => getSortOptions(sortDirection, activeSortKey, t),
    [sortDirection, activeSortKey, t],
  );

  function handleToolbarSortChanged(id: string): void {
    const opt = sortOptions.find((sO) => sO.id === id);
    if (isUndefined(opt)) {
      return;
    }

    onSortChanged(opt.key, opt.direction);
  }

  function onDeleteClicked(_assetId: number): void;
  function onDeleteClicked(rowId: string): void;
  function onDeleteClicked(id: number | string): void {
    setDeleteModalOpen(true);
    setAssetIdSelectedForDeletion(Number(id));
  }

  function handleDeleteAssetModalClosed() {
    setDeleteModalOpen(false);
    setAssetIdSelectedForDeletion(null);
  }

  function handleCompareClicked(): void {
    history.push(`/market?assets=${selectedRowsState.selectedRows.join(',')}`);
  }

  function closeCardPaymentErrorModal() {
    history.push({ pathname: history.location.pathname, search: history.location.search, hash: '' });
  }

  function closeCardPaymentSuccessModal() {
    history.push({ pathname: history.location.pathname, search: history.location.search, hash: '' });
  }

  const tableData = React.useMemo(
    () =>
      selectWatchlistTableData({
        onBidClicked: handleOpenBidModalClicked,
        onOfferClicked: handleOpenOfferModalClicked,
        onBuyNowClicked: handleOpenBuyNowModalClicked,
        onDeleteClicked,
        watchlistItems: results,
        clickableTradeTiles13816,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results],
  );

  const TableComponent = tableRefactor?.watchlist ? (
    <WatchlistTable
      assets={results}
      onBidClicked={handleOpenBidModalClicked}
      onOfferClicked={handleOpenOfferModalClicked}
      onBuyNowClicked={handleOpenBuyNowModalClicked}
      onDeleteClicked={onDeleteClicked}
      onEmptyClicked={() => history.push('/discover')}
      loading={loading}
      onSortChanged={onSortChanged}
      sortColumn={activeSortKey}
      sortDirection={sortDirection}
      error={error}
      perPage={rowsPerPage}
    />
  ) : (
    <Table
      data={tableData}
      headers={headers}
      loading={loading}
      error={error}
      emptyTableText={t('product:watchList.emptyTable')}
      EmptyTableAction={
        <Button variant="contained" color="primary" size="large" onClick={() => history.push('/discover')}>
          {t('common:discover')}
        </Button>
      }
    />
  );

  return (
    <div className={className}>
      <Toolbar
        resultCount={resultsCount}
        viewType={viewType}
        onChangeViewType={setViewType}
        hideChangeViewType={lessThanLg}
        displayCompareButton
        onSortChanged={handleToolbarSortChanged}
        sortOptions={sortOptions}
        onCompareClicked={handleCompareClicked}
      />
      {viewType === 'card' || lessThanLg ? (
        <div className={classes.cardList}>
          {loading &&
            !error &&
            new Array(20).fill(0).map(() => <WineCardSkeleton className={classes.card} key={uuid()} />)}
          {error && <ErrorPlaceholder error={error} />}
          {!results.length && !error && !loading ? (
            <EmptyPlaceholder
              label={t('product:watchList.emptyTable')}
              ActionButton={
                <Button variant="contained" color="primary" size="large" onClick={() => history.push('/discover')}>
                  {t('common:discover')}
                </Button>
              }
            />
          ) : (
            results.map((asset) => (
              <WatchlistAssetCard
                className={classes.card}
                key={asset.assetId.toString()}
                asset={asset}
                onBidClicked={handleOpenBidModalClicked}
                onOfferClicked={handleOpenOfferModalClicked}
                onDeleteClicked={onDeleteClicked}
                onBuyNowClicked={handleOpenBuyNowModalClicked}
              />
            ))
          )}
        </div>
      ) : (
        TableComponent
      )}
      <TablePagination
        component="div"
        rowsPerPageOptions={pageSizeVariants}
        count={resultsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onRowsPerPageChanged}
      />
      {assetId && modal === Modal.Bid && (
        <OrderModal
          assetId={Number(assetId)}
          open={modal === Modal.Bid}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="bid"
        />
      )}
      {assetId && modal === Modal.Offer && (
        <OrderModal
          assetId={Number(assetId)}
          open={modal === Modal.Offer}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="offer"
        />
      )}
      {assetId && modal === Modal.BuyNow && (
        <BuyNowModal assetId={Number(assetId)} open={modal === Modal.BuyNow} onClose={handleCloseModal} />
      )}
      {assetId && showTopUpBuySuccessSummaryModal && (
        <BuyNowModal assetId={Number(assetId)} open onClose={handleCloseModal} />
      )}
      {assetIdSelectedForDeletion && deleteModalOpen && (
        <DeleteWatchListItemModal
          assetId={assetIdSelectedForDeletion}
          open={deleteModalOpen}
          onClose={handleDeleteAssetModalClosed}
        />
      )}
      {showCarPaymentErrorModal && (
        <CardPaymentErrorModal open={showCarPaymentErrorModal} onClose={closeCardPaymentErrorModal} />
      )}
      {showCarPaymentSuccessModal && (
        <CardPaymentSuccessModal open={showCarPaymentSuccessModal} onClose={closeCardPaymentSuccessModal} />
      )}
    </div>
  );
}

export default function WatchlistAssetsWithContext(props: WatchlistAssetsProps): JSX.Element {
  return (
    // TODO: have the market comparison page expose this number so we can never get out of sync.
    // maxRows = 8 because that is the total columns on the market comparison page is 8
    <SelectedRowsProvider defaultState={{ maxRows: 8 }}>
      <WatchlistAssets {...props} />
    </SelectedRowsProvider>
  );
}
