import { UrlPath } from '../types/Enums';

export const makeUrlSafe = (wineName: string) => {
  return wineName
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading/trailing spaces
    .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-'); // Replace spaces with hyphens
};

export const getAssetIdFromUrl = (url: string) => {
  const urlParts = url.split('_');
  return urlParts[0];
};

interface ProductType {
  assetId: number;
  wineName: string;
  vintage: number;
  unitCount?: number;
  unitSize?: string;
}

export const getProductUrl = <T>(asset: T) => {
  const { assetId, wineName, vintage, unitCount, unitSize } = asset as unknown as ProductType;

  const unitsSizeStr = unitCount ? `${unitCount}x${unitSize}` : '';
  const safeWineName = makeUrlSafe(wineName);
  const pageSlug = `${assetId}_${safeWineName}_${vintage}_${unitsSizeStr}`;
  return `${UrlPath.PRODUCT_PAGE}/${pageSlug}`;
};
