import makeStyles from '@mui/styles/makeStyles';

export const useFooterStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: '0 20px',
    width: '100%',
  },

  footerLinks: {
    width: '100%',
    display: 'flex',
    // gap: theme.spacing(2),
    justifyContent: 'space-between',
  },

  link: {
    ...theme.typography.body1,
    color: 'white',
    textDecorationColor: 'white',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
}));
