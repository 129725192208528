/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { WatchlistAsset } from '../../../views/WatchList/selectors';
import WineCard from '..';

type WatchlistCardProps = {
  className?: string;
  asset: WatchlistAsset;
  onBidClicked: (rowId: string) => void;
  onOfferClicked: (rowId: string) => void;
  onDeleteClicked: (rowId: string) => void;
  onBuyNowClicked: (rowId: string) => void;
};

export default function WatchlistAssetCard({
  className = '',
  asset,
  onBidClicked,
  onOfferClicked,
  onDeleteClicked,
  onBuyNowClicked,
}: WatchlistCardProps): JSX.Element {
  const {
    appellation,
    vintage,
    score,
    region,
    performancePercentageDelta,
    name,
    lowestOffer,
    highestBid,
    unitSize,
    assetId,
    marketValue,
    unitCount,
    imageFilename,
  } = asset;

  return (
    <WineCard
      className={className}
      actions={{
        trade: {
          condensed: true,
          onBidClicked,
          onOfferClicked,
          onBuyClicked: onBuyNowClicked,
          assetId,
        },
        delete: {
          onDeleteClicked,
        },
      }}
      cardId={assetId.toString()}
      assetId={assetId}
      highestBid={highestBid}
      lowestOffer={lowestOffer}
      score={score}
      performancePercentageDelta={performancePercentageDelta}
      region={region}
      vintage={vintage}
      wineName={name}
      marketValue={marketValue}
      appellation={appellation}
      unitCount={unitCount}
      unitSize={unitSize}
      tradeTileVariant="unit-size"
      imageUrl={imageFilename}
    />
  );
}
