/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { Namespace, Resources, TFunction } from 'react-i18next';
import { GraphQLError } from 'graphql';
import { SxProps } from '@mui/material';
import { Link as RRLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import {
  BasicCell,
  CustomCell,
  Row,
  SortDirection,
  UnitSizeCell,
  WineNameCell,
} from '../../../../components/Table/types';
import { formatterWholeNumber } from '../../../../utils/currencyFormatter';
import TradingTile from '../../../../components/TradingTile';
import { SortOption } from '../../../../components/Table/SortMenu';
import { uuid } from '../../../../utils/uuid';
import { ActiveMarketSortObject } from './types';
import { isNullOrUndefined } from '../../../../utils/isNullOrUndefined';
import PopoverActionButton from '../../../../components/PopoverActionButton';
import { makeUrlSafe } from '../../../../utils/common';
import { UrlPath } from '../../../../types/Enums';
import Typography from '../../../../components/Typography';
import mapRegionName from '../../../../utils/mapRegionName';
import lightThemePalette from '../../../../theme/light/palette';
import { ActiveMarketExtended } from '../../../../types/ActiveMarketType';

type ActiveMarket = ActiveMarketExtended;
export interface TableData extends Row {
  wineName: WineNameCell;
  region: BasicCell;
  vintage: BasicCell;
  unitSize: UnitSizeCell;
  marketValue: BasicCell;
  highestBid: CustomCell;
  lowestOffer: CustomCell;
  spread: BasicCell;
}

interface SelectTableDataArgs {
  rawData: ActiveMarket[];
  handleAddToWatchlist: () => void;
  handleRemoveFromWatchlist: () => void;
  handleWatchlistError: (errors: readonly GraphQLError[]) => void;
  handleOpenBidModalClicked: (rowId: string) => void;
  handleOpenBuyNowModalClicked: (rowId: string) => void;
  handleOpenOfferModalClicked: (rowId: string) => void;
  clickableTradeTiles13816?: boolean;
  isLoggedIn?: boolean;
  handleSignInClick?: () => void;
}

export function selectTableData({
  handleAddToWatchlist,
  handleRemoveFromWatchlist,
  handleWatchlistError,
  handleOpenBidModalClicked,
  handleOpenBuyNowModalClicked,
  handleOpenOfferModalClicked,
  handleSignInClick = () => {},
  rawData,
  clickableTradeTiles13816,
  isLoggedIn = true,
}: SelectTableDataArgs): TableData[] {
  return rawData.map((asset) => {
    const { wineName, assetId, vintage, unitSize, unitCount } = asset;
    const unitsSizeStr = `${unitCount}x${unitSize}`;
    const safeWineName = makeUrlSafe(wineName);
    const pageSlug = `${assetId}_${safeWineName}_${vintage}_${unitsSizeStr}`;
    const tileStyle = {
      width: 70,
      height: 50,
      borderRadius: 10,
    };
    // eslint-disable-next-line no-nested-ternary
    const HighestBidTradingTile = isLoggedIn ? (
      clickableTradeTiles13816 ? (
        <TradingTile
          style={tileStyle}
          mode="bid"
          value={asset.highestBid.price}
          onClick={() => handleOpenBidModalClicked(asset.assetId.toString())}
        />
      ) : (
        <TradingTile
          style={tileStyle}
          mode="bid"
          value={asset.highestBid.price}
          createdDate={
            isNullOrUndefined(asset.highestBid.createdDate)
              ? ''
              : new Date(asset.highestBid.createdDate).toLocaleDateString()
          }
        />
      )
    ) : (
      <PopoverActionButton
        onSignInClicked={handleSignInClick}
        assetId={asset.assetId}
        onProceedClicked={() => handleOpenBidModalClicked(asset.assetId.toString())}
      >
        <TradingTile mode="bid" value={asset.highestBid.price} onClick={() => {}} />
      </PopoverActionButton>
    );

    // eslint-disable-next-line no-nested-ternary
    const LowestOfferTradingTile = isLoggedIn ? (
      clickableTradeTiles13816 ? (
        <TradingTile
          style={tileStyle}
          mode="offer"
          value={asset.lowestOffer.price}
          onClick={
            !isUndefined(asset.lowestOffer.price)
              ? () => handleOpenBuyNowModalClicked(asset.assetId.toString())
              : undefined
          }
        />
      ) : (
        <TradingTile
          style={tileStyle}
          mode="offer"
          value={asset.lowestOffer.price ?? 0}
          createdDate={
            isNullOrUndefined(asset.lowestOffer.createdDate)
              ? ''
              : new Date(asset.lowestOffer.createdDate).toLocaleDateString()
          }
        />
      )
    ) : (
      <PopoverActionButton
        onSignInClicked={handleSignInClick}
        assetId={asset.assetId}
        onProceedClicked={
          !isUndefined(asset.lowestOffer.price)
            ? () => handleOpenBuyNowModalClicked(asset.assetId.toString())
            : undefined
        }
      >
        <TradingTile mode="offer" value={asset.lowestOffer.price} onClick={() => {}} />
      </PopoverActionButton>
    );

    const getRegionColor = (regionName: string) => {
      const name = mapRegionName(regionName);
      const regionsColors = lightThemePalette.regions as Record<string, string>;

      return regionsColors[name];
    };

    const sxStyle: SxProps = {
      padding: 0,
      border: '1px solid #00000033',
      textAlign: 'center',
    };

    const regionBockColor = (
      <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
        <div
          style={{
            backgroundColor: getRegionColor(asset.region),
            width: '6px',
            height: '80px',
          }}
        />
        <Typography variant="subtitle2">{asset.region}</Typography>
      </div>
    );

    const getSpread = (spreadNumber: number | null | undefined) => {
      if (isNull(spreadNumber) || !spreadNumber) return '-';

      if (spreadNumber > 0) return <span className="tw-text-[#1FE85C]">{`+${spreadNumber}%`}</span>;

      return <span className="tw-text-[#EC2E48]">{`-${spreadNumber}%`}</span>;
    };

    return {
      rowId: asset.assetId.toString(),
      wineName: {
        customCell: (
          <Link
            className="tw-flex tw-flex-col tw-h-[70px] tw-justify-center tw-no-underline tw-text-black"
            component={RRLink}
            to={`${UrlPath.PRODUCT_PAGE}/${pageSlug}`}
          >
            <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <Typography variant="subtitle2">{asset.wineName}</Typography>

              <div className="tw-w-[40px] tw-min-w-[40px] tw-max-h-[25px] tw-h-[25px] tw-text-center tw-bg-[#B1DED8] tw-py-1 tw-ml-2 tw-rounded-[4px] tw-text-xs tw-flex tw-justify-center tw-items-center">
                <Typography variant="subtitle2">{asset?.score ?? '-'}</Typography>
              </div>
            </div>
          </Link>
        ),
        wineName: asset.wineName,
        region: asset.region,
        link: `${UrlPath.PRODUCT_PAGE}/${pageSlug}`,
        appellation: null,
        sx: { padding: 0, paddingX: 6, border: '1px solid #00000033' },
      },
      region: {
        mainContent: regionBockColor,
        sx: sxStyle,
      },
      vintage: {
        mainContent: asset.vintage,
        sx: sxStyle,
      },
      marketValue: {
        mainContent: asset.marketValue ? formatterWholeNumber.format(asset.marketValue) : '-',
        sx: sxStyle,
      },
      highestBid: {
        content: <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">{HighestBidTradingTile}</div>,
        sx: sxStyle,
      },
      lowestOffer: {
        content: <div className="tw-flex tw-flex-row tw-items-center tw-justify-center"> {LowestOfferTradingTile}</div>,
        sx: sxStyle,
      },
      spread: {
        mainContent: getSpread(isNull(asset.spread) ? null : asset.spread),
        sx: sxStyle,
      },
      unitSize: {
        unitCount: asset.unitCount,
        unitSize: asset.unitSize,
        sx: sxStyle,
      },
      actions: {
        watchlist: {
          onAdd: handleAddToWatchlist,
          onRemove: handleRemoveFromWatchlist,
          onError: handleWatchlistError,
        },
        trade: {
          assetId: asset.assetId,
          condensed: true,
          onBidClicked: handleOpenBidModalClicked,
          onBuyClicked: handleOpenBuyNowModalClicked,
          onOfferClicked: handleOpenOfferModalClicked,
        },
      },
    };
  });
}

export function selectSortOptions(
  sortDirection: SortDirection,
  activeSortKey: keyof ActiveMarketSortObject,
  t: TFunction<Namespace<keyof Resources>>,
): SortOption<keyof ActiveMarketSortObject>[] {
  const sortOptions: SortOption<keyof ActiveMarketSortObject>[] = [
    {
      // FIXME: These ids are recreated on each render, which is triggered by changing sort/filter/page.
      // https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/12699
      id: uuid(),
      direction: 'desc',
      key: 'BestBids',
      active: activeSortKey === 'BestBids',
      primaryLabel: t('discover:activeMarkets.sortOptions.bestBids'),
      secondaryLabel: t('search:sort.bestFirst'),
      filterKey: 'lowestOfferPrice',
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'BestOffers',
      active: activeSortKey === 'BestOffers',
      primaryLabel: t('discover:activeMarkets.sortOptions.bestOffers'),
      secondaryLabel: t('search:sort.bestFirst'),
      filterKey: 'lowestOfferPrice',
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'LatestBids',
      active: activeSortKey === 'LatestBids',
      primaryLabel: t('discover:activeMarkets.sortOptions.latestBids'),
      secondaryLabel: t('search:sort.newestToOldest'),
      filterKey: 'highestBidCreatedDate',
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'LatestOffers',
      active: activeSortKey === 'LatestOffers',
      primaryLabel: t('discover:activeMarkets.sortOptions.latestOffers'),
      secondaryLabel: t('search:sort.newestToOldest'),
      filterKey: 'lowestOfferCreatedDate',
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'LowestOffer',
      active: activeSortKey === 'LowestOffer' && sortDirection === 'asc',
      primaryLabel: t('discover:activeMarkets.sortOptions.lowestOffer'),
      secondaryLabel: t('search:sort.lowToHigh'),
      filterKey: 'lowestOfferPrice',
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'LowestOffer',
      active: activeSortKey === 'LowestOffer' && sortDirection === 'desc',
      primaryLabel: t('discover:activeMarkets.sortOptions.lowestOffer'),
      secondaryLabel: t('search:sort.highToLow'),
      filterKey: 'lowestOfferPrice',
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'HighestBid',
      active: activeSortKey === 'HighestBid' && sortDirection === 'desc',
      primaryLabel: t('discover:activeMarkets.sortOptions.highestBid'),
      secondaryLabel: t('search:sort.highToLow'),
      filterKey: 'highestBidPrice',
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'HighestBid',
      active: activeSortKey === 'HighestBid' && sortDirection === 'asc',
      primaryLabel: t('discover:activeMarkets.sortOptions.highestBid'),
      secondaryLabel: t('search:sort.lowToHigh'),
      filterKey: 'highestBidPrice',
    },

    {
      id: uuid(),
      direction: 'desc',
      key: 'WineName',
      active: activeSortKey === 'WineName' && sortDirection === 'desc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.zToA'),
      filterKey: 'wineName',
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'WineName',
      active: activeSortKey === 'WineName' && sortDirection === 'asc',
      primaryLabel: t('common:wineName'),
      secondaryLabel: t('search:sort.aToZ'),
      filterKey: 'wineName',
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'Vintage',
      active: activeSortKey === 'Vintage' && sortDirection === 'desc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.newestToOldest'),
      filterKey: 'vintage',
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'Vintage',
      active: activeSortKey === 'Vintage' && sortDirection === 'asc',
      primaryLabel: t('common:vintage'),
      secondaryLabel: t('search:sort.oldestToNewest'),
      filterKey: 'vintage',
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'MarketValue',
      active: activeSortKey === 'MarketValue' && sortDirection === 'desc',
      primaryLabel: t('search:filters.marketValue'),
      secondaryLabel: t('search:sort.highToLow'),
      filterKey: 'marketValue',
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'MarketValue',
      active: activeSortKey === 'MarketValue' && sortDirection === 'asc',
      primaryLabel: t('search:filters.marketValue'),
      secondaryLabel: t('search:sort.lowToHigh'),
      filterKey: 'marketValue',
    },

    {
      id: uuid(),
      direction: 'desc',
      key: 'Region',
      active: activeSortKey === 'Region' && sortDirection === 'desc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.zToA'),
      filterKey: 'region',
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'Region',
      active: activeSortKey === 'Region' && sortDirection === 'asc',
      primaryLabel: t('common:region'),
      secondaryLabel: t('search:sort.aToZ'),
      filterKey: 'region',
    },
    {
      id: uuid(),
      direction: 'desc',
      key: 'UnitSize',
      active: activeSortKey === 'UnitSize' && sortDirection === 'desc',
      primaryLabel: t('discover:activeMarkets.sortOptions.unitSize'),
      secondaryLabel: t('search:sort.highToLow'),
      filterKey: 'unitSize',
    },
    {
      id: uuid(),
      direction: 'asc',
      key: 'UnitSize',
      active: activeSortKey === 'UnitSize' && sortDirection === 'asc',
      primaryLabel: t('discover:activeMarkets.sortOptions.unitSize'),
      secondaryLabel: t('search:sort.lowToHigh'),
      filterKey: 'unitSize',
    },
  ];

  return sortOptions;
}
