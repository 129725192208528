import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ReactComponent as ProducerIcon } from '../../../assets/icons/wine-details-producer.svg';
import { ReactComponent as VintageIcon } from '../../../assets/icons/wine-details-vintage.svg';
import { ReactComponent as TypeIcon } from '../../../assets/icons/wine-details-type.svg';
import { ReactComponent as ColourIcon } from '../../../assets/icons/wine-details-colour.svg';
import { ReactComponent as RegionIcon } from '../../../assets/icons/wine-details-region.svg';
import { ReactComponent as AppellationIcon } from '../../../assets/icons/wine-details-appellation.svg';
import ClassificationIcon from '../../../assets/icons/wine-details-classification.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    '& :first-letter': {
      textTransform: 'capitalize',
    },
    minWidth: '50%',
  },
  iconContainer: {
    width: 35,
  },
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
  },
  bold: {
    fontWeight: 'bold',
  },
}));

interface Props {
  title: string;
  label: string;
}

export const winDetailsIcon = (iconTitle: string): JSX.Element | null => {
  switch (iconTitle) {
    case 'Producer':
      return <ProducerIcon />;
    case 'Vintage':
      return <VintageIcon />;
    case 'Type':
      return <TypeIcon />;
    case 'Colour':
      return <ColourIcon />;
    case 'Region':
      return <RegionIcon />;
    case 'Appellation':
      return <AppellationIcon />;
    case 'Classification':
      return <img src={ClassificationIcon} alt="classification" />;
    default:
      return null;
  }
};

export default function BulletPoint({ title, label }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>{winDetailsIcon(title)}</div>
      <div className={classes.verticalContainer}>
        <Typography>{title}</Typography>
        <Typography className={classes.bold}>{label}</Typography>
      </div>
    </div>
  );
}
