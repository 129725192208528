import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import { calculateFee } from '@cultwines/zellar-client-sdk/utils/calculateFee';
import { Fees } from '@cultwines/zellar-client-sdk/types/Fees';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { Grid, Stack, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from '@apollo/client';
import { DialogContent } from '../Dialog/DialogContent';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';
import { ProductData, Unit } from './types';
import { formatter, formatterWholeNumber } from '../../utils/currencyFormatter';
import { useAvailableBalance } from '../../hooks/useAvailableBalance';
import UnitPicker from './UnitPicker';
import TopUpModal from '../TopUpModal';
import UnitBreakDownContent from './UnitBreakDownContent';
import { logError } from '../../utils/logger';
import Divider from '../Divider';
import { useWalletBalance } from '../../hooks';
import { useQueryParameters } from '../../hooks/useQueryParameters';
import { isNull } from '../../utils/isNull';
import CultXTooltip from '../CultxTooltip';
import useUserDetails from '../../hooks/useUserDetails';
import { AccountType } from '../../types/AccountType';
import PaymentCardForm from '../PaymentCardModal/form';
import BillingAddress from '../TopUpModal/BillingAddress';
import { useAddress } from '../../hooks/useAddress';
import { GET_STORED_CARD_DETAILS } from '../../graphql/queries/storedCardDetails';
import PayByCard from '../TopUpModal/PayByCard';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card.svg';

const useStyles = makeStyles((theme) => ({
  modalContainer: { width: '100%', paddingLeft: theme.spacing(7.5), paddingRight: theme.spacing(7.5) },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'stretch',
  },
  infoCardsContainer: { display: 'flex', justifyContent: 'space-between' },
  infoCards: { display: 'flex', gap: theme.spacing(3) },
  footer: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
  },
  formContainer: {
    display: 'flex',
  },
  tradingCard: {
    width: '160px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  field: {
    width: '100%',
  },
  detailRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  textCenter: {
    textAlign: 'center',
  },
  button: {
    display: 'flex',
    width: '60%',
    alignSelf: 'center',
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '12px',
    marginBottom: '-4px',
  },
  itemContainer: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '4px',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  quantityContainer: {
    marginTop: theme.spacing(8),
  },
  quantityIcon: {
    fontSize: '2.2em',
    cursor: 'pointer',
  },
  availableCreditValue: {
    marginTop: theme.spacing(1),
    fontSize: '1.4em',
    fontWeight: 'bold',
  },
  fontSize18px: {
    fontSize: '18px',
  },
  fontWeight400: {
    fontWeight: 400,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  balanceBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },
  cardButton: {
    width: '100%',
    background: 'white',
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    padding: theme.spacing(3),
  },
  cardIcon: {
    stroke: theme.palette.textPrimary,
  },
  disclaimerText: {
    color: '#8A809A',
    fontSize: '10px',
    lineHeight: '12px',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  linkText: {
    color: '#FB9D0E',
  },
}));

function calculateHighestBidPrice(units: Unit[]): number {
  if (units.length === 0) {
    return 0;
  }
  // clone a new array to avoid sort method change the original array
  const highestBid = units.slice().sort((a, b) => b.price - a.price)[0].price;
  const highestBidInclFees = calculateFee(highestBid, Fees.Standard);
  return highestBidInclFees;
}

interface BuyNowDetailsFormProps {
  onClose: () => void;
  onError: () => void;
  productData: ProductData | null;
  onSubmit: () => void;
  quantity: number;
  maxQuantity: number;
  totalUnitPrice: number;
  transactionFee: number;
  vat?: number;
  totalUnitPricePlusFees: number;
  quantityUpdated: (quantity: number) => void;
  loading: boolean;
  unitBreakDownItems: Array<Unit>;
  showFees?: boolean;
}

export default function BuyNowFormContent({
  onClose,
  onError,
  productData,
  onSubmit,
  quantity,
  maxQuantity,
  totalUnitPrice,
  transactionFee,
  vat,
  totalUnitPricePlusFees,
  quantityUpdated,
  loading,
  unitBreakDownItems,
  showFees = true,
}: BuyNowDetailsFormProps): JSX.Element {
  const { t } = useTranslation();
  const { topUpWhileBuying } = useFlags();
  const classes = useStyles();
  const theme = useTheme();
  const queryParams = useQueryParameters();
  const quantityParam = queryParams.get('quantity');
  const [showTopUpModal, setShowTopUpModal] = React.useState(false);
  const [addressModal, setAddressModal] = React.useState(false);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { userDetails, loading: loadingUserDetails, error: failedToLoadUserDetails } = useUserDetails();
  const isWhaleAccount = userDetails?.accountType === AccountType.Whale;
  // eslint-disable-next-line no-nested-ternary
  const tradingHeadroomTitle = userDetails?.mitConsentedFlag
    ? isWhaleAccount
      ? t('wallet:tradingHeadroomTooltipMITEnabledWhaleAccount')
      : t('wallet:tradingHeadroomTooltipMITEnabled')
    : t('wallet:tradingHeadroomTooltipMITDisabled');
  const { data: storedCardDetails } = useQuery(GET_STORED_CARD_DETAILS);
  const { address, loading: loadingAddress, error: addressError } = useAddress({ addressType: 'Billing' });
  const {
    availableBalance,
    loading: loadingAvailableBalance,
    error: failedToLoadAvailableBalance,
  } = useAvailableBalance();
  const { walletBalance: fundsOnAccount, loading: loadingFundsOnAccount } = useWalletBalance();
  const notEnoughTradingHeadroom = !loadingAvailableBalance && availableBalance < totalUnitPricePlusFees;
  const highestBidTooLarge =
    !loadingUserDetails &&
    userDetails?.accountType !== AccountType.Whale &&
    !loadingFundsOnAccount &&
    !isNull(fundsOnAccount) &&
    fundsOnAccount < calculateHighestBidPrice(unitBreakDownItems);
  const balanceToBuyError = notEnoughTradingHeadroom || highestBidTooLarge;
  const cardCharge = Number((totalUnitPricePlusFees - (fundsOnAccount ?? 0)).toFixed(2));

  React.useEffect(() => {
    if (failedToLoadUserDetails) {
      logError({ error: failedToLoadUserDetails, filename: 'BuyNowFormContent' });
      onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedToLoadUserDetails]);

  React.useEffect(() => {
    if (failedToLoadAvailableBalance) {
      logError({ error: failedToLoadAvailableBalance, filename: 'BuyNowFormContent' });
      onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedToLoadAvailableBalance]);

  function handleReviewOrder() {
    onSubmit();
  }

  // this allows to dynamically navigate to summary modal after client has
  // succesfully toped up inside buy now modal
  React.useEffect(() => {
    if (quantityParam) {
      quantityUpdated(+quantityParam);
      setTimeout(() => {
        handleReviewOrder();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleAdd() {
    if (quantity === maxQuantity) {
      return;
    }
    quantityUpdated(quantity + 1);
  }
  function handleReduce() {
    if (quantity === 1) {
      return;
    }
    quantityUpdated(quantity - 1);
  }

  function handleTopUp() {
    setShowTopUpModal(true);
  }

  function handleAddressClick() {
    setShowTopUpModal(true);
    setAddressModal(true);
  }

  const disabledFramesCTA =
    !address ||
    !isFormValid ||
    loadingFundsOnAccount ||
    loadingAvailableBalance ||
    !!failedToLoadAvailableBalance ||
    loadingUserDetails ||
    loading;

  return (
    <>
      <div className={classes.modalContainer}>
        <DialogTitle id="dialog-title" onClose={onClose}>
          {t('product:detailsModal.buyNowTitle')}
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.contentContainer}>
            {!loading && productData ? (
              <Typography variant="h4">{productData.wineName}</Typography>
            ) : (
              <Typography variant="h2">
                <Skeleton />
              </Typography>
            )}
            {!loading && productData ? (
              <>
                <UnitPicker
                  selectedUnitCount={quantity}
                  availableUnitCount={maxQuantity}
                  handleAdd={handleAdd}
                  handleMinus={handleReduce}
                  unitSize={productData.unitSize}
                />
                <UnitBreakDownContent unitBreakDownItems={unitBreakDownItems} />

                {balanceToBuyError && topUpWhileBuying && (
                  <Stack bgcolor={theme.palette.grey[50]} mx={1}>
                    <div className={classes.cardButton}>
                      <CreditCardIcon className={classes.cardIcon} />
                      <Typography variant="subtitle1">{t('wallet:payByCard.title')}</Typography>
                    </div>
                    {storedCardDetails?.paymentGetCardDetails?.some((e) => e.defaultMitFlag) ? (
                      <PayByCard
                        onlyShowDefault
                        hideUseDifferentCard
                        amount={cardCharge}
                        isSubmitting={isSubmitting}
                        setIsFormValid={setIsFormValid}
                        setIsSubmitting={setIsSubmitting}
                        customSuccessUri={`?assetId=${productData.assetId}&quantity=${quantity}&cardCharge=${cardCharge}#buy_topup`}
                      />
                    ) : (
                      <PaymentCardForm
                        showStoreCard
                        setIsFormValid={setIsFormValid}
                        setIsSubmitting={setIsSubmitting}
                        isSubmitting={isSubmitting}
                        customSuccessUri={`?assetId=${productData.assetId}&quantity=${quantity}&cardCharge=${cardCharge}#buy_topup`}
                        defaultMitFlag={false}
                        mitConsentedFlag={false}
                        showEnableMIT={false}
                        showMakeDefault={false}
                        amount={cardCharge}
                      />
                    )}
                    {!address && !addressError && !loadingAddress && (
                      <>
                        <Divider />
                        <BillingAddress address={address} openAddressModal={handleAddressClick} />
                      </>
                    )}
                  </Stack>
                )}

                <div className={classes.itemContainer} data-testid="cost-breakdown">
                  <div className={classes.detailRow}>
                    <Typography className={clsx(classes.fontSize18px, classes.fontWeight400)}>
                      {t('product:detailsModal.buyNow.totalUnitPrice')}
                    </Typography>
                    <Typography className={clsx(classes.fontSize18px, classes.fontWeight400)}>
                      {formatter.format(totalUnitPrice)}
                    </Typography>
                  </div>
                  {showFees && (
                    <>
                      <div className={classes.detailRow}>
                        <Typography className={clsx(classes.fontSize18px, classes.fontWeight400)}>
                          {t('product:detailsModal.buyNow.transactionFee', { fee: 2.5 })}
                        </Typography>
                        <Typography className={clsx(classes.fontSize18px, classes.fontWeight400)}>
                          {formatter.format(transactionFee)}
                        </Typography>
                      </div>
                      {!isUndefined(vat) && (
                        <div className={classes.detailRow}>
                          <Typography className={clsx(classes.fontSize18px, classes.fontWeight400)}>
                            VAT (20%)
                          </Typography>
                          <Typography className={clsx(classes.fontSize18px, classes.fontWeight400)}>
                            {formatter.format(vat)}
                          </Typography>
                        </div>
                      )}
                    </>
                  )}
                  <div className={classes.detailRow}>
                    <Typography className={clsx(classes.fontSize18px, classes.fontWeight700)}>
                      {t('product:detailsModal.buyNow.totalPrice')}
                    </Typography>
                    <Typography className={clsx(classes.fontSize18px, classes.fontWeight700)} data-testid="total-price">
                      {formatter.format(totalUnitPricePlusFees)}
                    </Typography>
                  </div>
                  {topUpWhileBuying && balanceToBuyError && (
                    <>
                      <div className={classes.detailRow}>
                        <Typography className={clsx(classes.fontSize18px, classes.fontWeight400)}>
                          {t('product:detailsModal.totalFunds')}
                        </Typography>
                        <Typography
                          className={clsx(classes.fontSize18px, classes.fontWeight400)}
                          data-testid="total-price"
                        >
                          {isNull(fundsOnAccount) ? t('common:currentlyUnavailable') : formatter.format(fundsOnAccount)}
                        </Typography>
                      </div>
                      <div className={classes.detailRow}>
                        <Typography className={clsx(classes.fontSize18px, classes.fontWeight400)}>
                          {t('product:detailsModal.buyNow.cardCharge')}
                        </Typography>
                        <Typography
                          className={clsx(classes.fontSize18px, classes.fontWeight400)}
                          data-testid="total-price"
                        >
                          {formatter.format(cardCharge)}
                        </Typography>
                      </div>
                    </>
                  )}
                </div>
                {loadingAvailableBalance ? (
                  <Skeleton height="120px" />
                ) : (
                  <div className={classes.itemContainer}>
                    <div className={classes.detailRow}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <div className={classes.detailRow}>
                            <div>
                              <Typography
                                style={{ display: 'inline', marginRight: 3 }}
                                className={classes.uppercase}
                                customVariant="label2"
                                zellarColour="textLabel"
                              >
                                {t('product:detailsModal.totalFunds')}
                              </Typography>
                              <CultXTooltip title={t('wallet:fundsOnAccountTooltip')} />
                              <Typography customVariant="h5" className={classes.textCenter}>
                                {isNull(fundsOnAccount)
                                  ? t('common:currentlyUnavailable')
                                  : formatterWholeNumber.format(fundsOnAccount)}
                              </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div>
                              <Typography
                                style={{ display: 'inline', marginRight: 3 }}
                                className={classes.uppercase}
                                customVariant="label2"
                                zellarColour="textLabel"
                              >
                                {t('product:detailsModal.availableBalance')}
                              </Typography>
                              <CultXTooltip aria-label="tradingheadroom" title={tradingHeadroomTitle} />
                              <Typography customVariant="h5" className={classes.textCenter}>
                                {formatterWholeNumber.format(availableBalance)}
                              </Typography>
                            </div>
                          </div>
                        </Grid>

                        <Grid item xs={12} md={6} className={classes.balanceBtnContainer}>
                          <Button color="info" variant="outlined" onClick={handleTopUp}>
                            {t('product:detailsModal.balanceButton')}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                )}

                <Typography className={classes.disclaimerText}>
                  {t('common:disclaimer.text1')}{' '}
                  <a
                    href="https://www.cultx.com/delivery"
                    className={classes.linkText}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('common:disclaimer.link')}
                    {'. '}
                  </a>
                  {t('common:disclaimer.text2')}
                </Typography>
              </>
            ) : (
              <Skeleton width="100%" height="400px" variant="rectangular" />
            )}
            {balanceToBuyError && (
              <Alert variant="filled" severity="error">
                <AlertTitle>
                  {topUpWhileBuying
                    ? t('product:detailsModal.error.youWillBeCharged')
                    : t('product:detailsModal.error.attention')}
                </AlertTitle>
                {topUpWhileBuying
                  ? t('product:detailsModal.buyNow.notEnoughFundsCC')
                  : t('product:detailsModal.buyNow.notEnoughFunds')}
              </Alert>
            )}

            {balanceToBuyError && topUpWhileBuying ? (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={disabledFramesCTA}
                onClick={() => setIsSubmitting(true)}
              >
                {t('product:detailsModal.buyNow.reviewButtonCC')}
              </Button>
            ) : (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={loading || balanceToBuyError || loadingUserDetails}
                onClick={handleReviewOrder}
              >
                {t('product:detailsModal.buyNow.reviewButton')}
              </Button>
            )}
          </div>
        </DialogContent>
      </div>
      {showTopUpModal && (
        <TopUpModal
          open={showTopUpModal}
          onClose={() => {
            setShowTopUpModal(false);
            if (addressModal) {
              setAddressModal(false);
            }
          }}
          isAddressForm={addressModal}
        />
      )}
    </>
  );
}
